<h5 class="oposition-title" translate="users.card.rgpd.opposition.title"></h5>
<br>
<span  translate="users.card.rgpd.opposition.text"></span>
<br>
<br>
Activé : <nb-toggle></nb-toggle>
<br>
<br>

<h5 translate="users.card.rgpd.forget.title"></h5>
<br>
<br>
<span translate="users.card.rgpd.forget.text"></span>
<br>
<br>
<button nbButton translate="users.card.rgpd.forget.button"></button>
