import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UsersJson, UserJson } from '../@types/UserJson';
import { GroupsJson, GroupJson } from '../@types/GroupJson';
import { SitesJson } from '../@types/SiteJson';
import { VariablesJson } from '../@types/VariableJson';
import { FeatureSurveysJson } from '../@types/FeatureSurveyJson';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { UnavailableServiceService } from './unavailable-service.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  currentUser: UsersJson;
  currentUserGroups: GroupsJson;

  constructor(
    private httpClient: HttpClient,
    private unavailableServiceService: UnavailableServiceService) {}

  getUser(userID) {
    if (userID === 'current' && this.currentUser) {
      return of(this.currentUser);
    }

    return this.unavailableServiceService.handle(
      this.httpClient.get<UsersJson>(`/api/users/${userID}`),
      `/api/users/${userID}`,
    ).pipe(
      map((user) => {
        if (userID === 'current') {
          this.currentUser = user;
        }

        return user;
      })
    );
  }

  findUser(filter={}) {
    const filterString = Object.keys(filter).reduce((str, key) => {
      str = `${str.length ? '&' : '?'}${key}=${filter[key]}`;
      return str;
    }, '');

    return this.unavailableServiceService.handle(
      this.httpClient.get<UsersJson>(`/api/users${filterString}`),
      `/api/users${filterString}`,
    );
  }

  getUsersByGroupId(groupId) {
    return this.unavailableServiceService.handle(
      this.httpClient.get<UsersJson>(`/api/users?g=_objectid:${groupId}`)
        .pipe(map(users => users.users)),
      `/api/users?g=_objectid:${groupId}`,
    );
  }

  getUserGroups(userID) {
    if (userID === 'current' && this.currentUserGroups) {
      return of(this.currentUserGroups);
    }

    return this.unavailableServiceService.handle(
      this.httpClient.get<GroupsJson>(`/api/users/${userID}/groups`),
      `/api/users/${userID}/groups`,
    ).pipe(
      map((groups) => {
        if (userID === 'current') {
          this.currentUserGroups = groups;
        }
        return groups;
      })
    );
  }

  getUserGroupFeaturesKeys(userID): Observable<Set<string>> {
    return this.getUserGroups(userID)
      .pipe(catchError((err) => []))
      .pipe(
        map((groups) => new Set<string>(groups.groups
            .filter(e => e.admin && e.adminConfig)
            .map(e => (e.permissions || []).map(el => el.key))
            .reduce((m, e) => ([...m, ...e]), []))
        )
      );
  }

  getUserGroupFeatureOptions(userID, featureKey): Observable<{ [key: string]: any }> {
    return this.getUserGroups(userID)
      .pipe(
        map((groups) => {
          const group = groups.groups.find(gr => gr.permissions.find(feature => feature.key === featureKey));
          return group ? group.permissions.find(feature => feature.key === featureKey).options : {};
        })
      );
  }

  createUser(user: UserJson) {
    return this.unavailableServiceService.handle(
      this.httpClient.post<UserJson>(`/api/users`, user),
      `/api/users`,
    );
  }

  updateUser(id: string, updates) {
    return this.unavailableServiceService.handle(
      this.httpClient.put<UserJson>(`/api/users/${id}`, updates),
      `/api/users/${id}`,
    );
  }

  deactivateUser(userID: string, deactivatedGroupID: string) {
    return this.unavailableServiceService.handle(
      this.httpClient.put<UserJson>(
        `/api/users/${userID}`,
        {
          g: [`_objectid:${deactivatedGroupID}`]
        }
      ),
      `/api/users/${userID}`,
    );
  }

  getGroup(groupID) {
    return this.unavailableServiceService.handle(
      this.httpClient.get<GroupsJson>(`/api/groups/${groupID}`),
      `/api/groups/${groupID}`,
    );
  }

  getGroups(): Observable<Array<GroupJson>> {
    return this.unavailableServiceService.handle(
      this.httpClient.get<GroupsJson>(`/api/groups`).pipe(map((groups) => groups.groups)),
      `/api/groups`,
    );
  }

  getUserSites(userID) {
    return this.unavailableServiceService.handle(
      this.httpClient.get<SitesJson>(
        `/api/users/${userID}/sites`,
        {
          headers: {
            'x-gp-data-model-version': 'ANYTHING_TO_AVOID_MAPPING_TO_OLD_VERSION',
          }
        }
      ).pipe<SitesJson>(
        map(sites => ({
          sites: sites.sites.map(site => {
            if (site.contractsTimeline) {
              return site;
            }
            return {
              ...site,
              contractsTimeline: [
                {
                  from: new Date(site.begin_date),
                  to: null,
                  status: site.contract_specific.status,
                  billingType: site.contract_specific.billing_type,
                  meterType: site.contract_specific.is_amm ? 'AMM' : 'EM',
                  offerLabel: site.contract_specific.offer_label,
                  power: site.contract_specific.sub_power,
                  pricingPeriods: site.contract_specific.sub_service === 'heures creuses' ? '2' : '1',
                  tarificationCalendarId: null,
                  contract: {
                    ref: '',
                    isProvisional: false,
                    status: 1,
                  },
                }
              ]
            };
          })
        }))
      ),
      `/api/users/${userID}/sites`,
    );
  }

  getSite(siteID) {
    return this.unavailableServiceService.handle(
      this.httpClient.get<SitesJson>(
        `/api/sites/${siteID}`,
        {
          headers: {
            'x-gp-data-model-version': 'ANYTHING_TO_AVOID_MAPPING_TO_OLD_VERSION',
          }
        }
      ),
      `/api/sites/${siteID}`,
    );
  }

  getSiteVariables(siteID) {
    return this.unavailableServiceService.handle(
      this.httpClient.get<VariablesJson>(`/api/sites/${siteID}/variables`),
      `/api/sites/${siteID}/variables`,
    );
  }

  getUserSurvey(userID) {
    return this.unavailableServiceService.handle(
      this.httpClient.get<FeatureSurveysJson>(`/api/users/${userID}/features/feature_survey`),
      `/api/users/${userID}/features/feature_survey`,
    );
  }

  getUserSiteSurvey(userID, siteID) {
    return this.unavailableServiceService.handle(
      this.httpClient.get<FeatureSurveysJson>(`/api/users/${userID}/features/feature_survey?sid=_objectid:${siteID}`),
      `/api/users/${userID}/features/feature_survey?sid=_objectid:${siteID}`,
    );
  }

  generateActivationLink(userID){
    return this.unavailableServiceService.handle(
      this.httpClient.put(`/api/users/${userID}/activation`, null),
      `/api/users/${userID}/activation`,
    );
  }
}
