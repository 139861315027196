import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'gp-unavailable-service-notice',
  templateUrl: './unavailable-service-notice.component.html',
  styleUrls: ['./unavailable-service-notice.component.scss']
})
export class UnavailableServiceNoticeComponent implements OnInit {

  translations: any;

  constructor(private translateService: TranslateService) { }

  ngOnInit() {
    this.translateService.onLangChange.subscribe(() => this.onLanguageChange());
    this.onLanguageChange();
  }

  onLanguageChange() {
    this.translateService.get('unavailable')
      .subscribe(data => this.translations = data);
  }

  reload() {
    window.location.reload();
  }
}
