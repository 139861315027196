import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { VariablesJson } from '../../../../@types/VariableJson';
import { SiteJson } from '../../../../@types/SiteJson';
import {
  faMale,
  faHome,
  faBuilding,
  faShower,
  faFire,
  faSnowflake,
  faUtensils,
  faKey,
  faUmbrellaBeach,
  faBolt,
} from '@fortawesome/free-solid-svg-icons';
import { UserService } from '../../../../services/user.service';
import { GroupJson } from '../../../../@types/GroupJson';
import { UserDisplayJson } from '../../../../@types/UserDisplayJson';

@Component({
  selector: 'gp-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
})
export class UserDetailsComponent implements OnInit, OnChanges {
  @Input() userSites: Array<SiteJson>;
  @Input() user: UserDisplayJson;
  @Input() userGroups: Array<GroupJson>;

  offerTypes = { gas: 'Gaz', electricity: 'Électrique' };
  featureSurveysInfo: Array<{
    sid: string | null;
    people: number;
    isHouse: boolean;
    area: number;
    isPrincipal: boolean;
    hasClim: boolean;
    hasElecHeating: boolean;
    hasElecECS: boolean;
    hasElecCooking: boolean;
    hasGasHeating: boolean;
    hasGasECS: boolean;
    hasGasCooking: boolean;
  }> = [];

  groupName = '';
  groupNameMapping: {
    user: string;
    admin: string;
    support: string;
    'disabled-user': string;
    'uninitialized-user': string;
  };

  faPeopleIcon = faMale;
  faHouseIcon = faHome;
  faBuildingIcon = faBuilding;
  faShowerIcon = faShower;
  faHeatingIcon = faFire;
  faClimIcon = faSnowflake;
  faCookingIcon = faUtensils;
  faSecondaryHouseIcon = faUmbrellaBeach;
  faPrincipalHouseIcon = faKey;
  faBolt = faBolt;
  faFire = faFire;

  constructor(
    private userService: UserService) {}

  ngOnInit() {}

  ngOnChanges() {
    this.userService.getUserSurvey(this.user._id)
      .subscribe(features => {
        this.featureSurveysInfo = features.feature_survey.map(survey => ({
          sid: survey.sid,
          people: survey.habitants ? Number.parseInt(survey.habitants.replace('habitants_mod_', ''), 10) : 0,
          isHouse: survey.live_in === 'live_in_mod_1',
          area: Number.parseInt(survey.area, 10),
          isPrincipal: survey.principal_house === 'principal_house_mod_1',
          hasClim:
            survey.has_cooling_system === 'has_cooling_system_mod_1' &&
            survey.cooling_system_ind_or_coll === 'cooling_system_ind_or_coll_mod_2',
          hasElecHeating: survey.individual_heat_energy === 'individual_heat_energy_mod_1',
          hasElecECS: survey.individual_water_energy === 'individual_water_energy_mod_2',
          hasElecCooking: (survey.cooking_machine || []).some(
            e => ['cooking_machine_mod_2', 'cooking_machine_mod_3', 'cooking_machine_mod_4'].includes(e)),
          hasGasHeating: survey.individual_heat_energy === 'individual_heat_energy_mod_3',
          hasGasECS: survey.individual_water_energy === 'individual_water_energy_mod_1',
          hasGasCooking: (survey.cooking_machine || []).some(
            e => ['cooking_machine_mod_1', 'cooking_machine_mod_5'].includes(e)),
      }));
    });
  }

  hasActiveContract(site) {
    return site.contractsTimeline && site.contractsTimeline.length && site.contractsTimeline.find(e => e.status === 1)
      || site.contract_specific && site.contract_specific.status === 1;
  }

  hasContract(site) {
    return site.contract_specific || (site.contractsTimeline && site.contractsTimeline.length);
  }

  getSiteLastContract(site) {
    const activeContract =  site.contractsTimeline.find(e => e.status === 1);
    return activeContract || site.contractsTimeline[site.contractsTimeline.length - 1];
  }

  getSiteFirstContract(site) {
    return site.contractsTimeline[0];
  }

  getCurrentContractPower(site) {
    const contract = this.getSiteLastContract(site);

    if (!contract) {
      return undefined;
    }

    if (contract.power instanceof Object) {
      return contract.power.PEAK || contract.power.OFFPEAK || contract.power.BASE;
    }

    return contract.power;
  }

  getContractSurvey(site) {
    const siteSurvey = this.featureSurveysInfo.find(e => e.sid === site._id);

    if (siteSurvey) {
      return siteSurvey;
    }

    const generalSurvey = this.featureSurveysInfo.find(e => !e.sid);

    if (generalSurvey) {
      return generalSurvey;
    }

    return null;
  }

  getRefPDS(site) {
    if (site.ref_pds) {
      return site.ref_pds;
    }

    // eslint-disable-next-line no-underscore-dangle
    if (site._ref_pds) {
    // eslint-disable-next-line no-underscore-dangle
      return site._ref_pds;
    }

    if (site.contractsTimeline && site.contractsTimeline.length) {
      return site.contractsTimeline[site.contractsTimeline.length - 1].meterId;
    }

    return '-';
  }

  isAMM(site) {
    return this.getSiteLastContract(site).meterType.includes('AMM');
  }
}
