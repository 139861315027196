import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SigninComponent } from './signin/signin.component';
import {SharedModule} from '../shared/shared.module';
import {SigninExtComponent} from './signin-ext/signin-ext.component';

@NgModule({
  declarations: [SigninComponent, SigninExtComponent],
    imports: [
        CommonModule,
        SharedModule,
    ],
  exports : [SigninComponent, SigninExtComponent]
})
export class SigninModule { }
