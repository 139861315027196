<nb-reveal-card [nbSpinner]="nbSpinner" [showToggleButton]="false" [revealed]="revealed" [style]="{height: height}">
    <nb-card-front class="h-100">
        <nb-card class="h-100">
            <ng-content class="h-100"></ng-content>
            <div class="options">
                <nb-icon (click)="toggleView()" icon="list-outline" pack="eva"></nb-icon>
            </div>
        </nb-card>
    </nb-card-front>
    <nb-card-back class="h-100">
        <nb-card class="h-100">
            <gp-analytic-card-back [columns]="columns" [data]="data" [showIndex]="showIndex"></gp-analytic-card-back>
            <div class="options">
                <nb-icon (click)="toggleView()" icon="pie-chart-outline" pack="eva"></nb-icon>
            </div>
        </nb-card>
    </nb-card-back>
</nb-reveal-card>