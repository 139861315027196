import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ReportingReceiversJson } from '../@types/ReportingReceiverJson';
import { UnavailableServiceService } from './unavailable-service.service';

@Injectable({
  providedIn: 'root'
})
export class ReportingReceiversService {
  powervasAPI = '/api/';

  constructor(private httpClient: HttpClient, private unavailableServiceService: UnavailableServiceService) { }

  getReportingReceivers() {
    return this.unavailableServiceService.handle(
      this.httpClient.get<ReportingReceiversJson>(`${this.powervasAPI}/features/report_receivers`),
      this.powervasAPI,
    );
  }

  removeReportingReceiver(id) {
    return this.unavailableServiceService.handle(
      this.httpClient.delete(`${this.powervasAPI}/features/report_receivers/${id}`),
      this.powervasAPI,
    );
  }

  addReceiver(email) {
    return this.unavailableServiceService.handle(
      this.httpClient.post(`${this.powervasAPI}/features/report_receivers`, { email, active: true }),
      this.powervasAPI,
    );
  }
}
