import { Component, OnInit, Input } from '@angular/core';
import { UserJson } from '../../../../@types/UserJson';
import { VariablesJson } from '../../../../@types/VariableJson';
import { SiteJson } from '../../../../@types/SiteJson';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AppConfig } from '../../../../shared/services/app-config.service';

@Component({
  selector: 'gp-user-link-generation',
  templateUrl: './user-link-generation.component.html',
  styleUrls: ['./user-link-generation.component.scss'],
})
export class UserLinkGenerationComponent implements OnInit {
  @Input() userSites: Array<SiteJson>;
  @Input() user: UserJson;
  @Input() sitesVariables: Array<VariablesJson>;
  link = '';
  form: FormGroup;

  constructor(
    private appConfig: AppConfig,
  ) {}

  ngOnInit() {
    this.form = new FormGroup({
      ref: new FormControl('', Validators.required),
      feature: new FormControl('invoices', Validators.required)
    });
  }

  generateLink() {
    let link = this.appConfig.get('url_ael');
    link += this.form.value.feature;
    if (this.form.value.ref) {
      link += '?contract=' + this.form.value.ref;
    }
    this.link = link;
  }

  selectLink() {
    const inputField = document.getElementById('link') as HTMLInputElement;
    inputField.select();
  }

  copyLink() {
    const inputField = document.getElementById('link') as HTMLInputElement;
    inputField.select();
    navigator.clipboard.writeText(this.link);
    inputField.value = 'Lien copié !';
    setTimeout(() => (inputField.value = this.link), 1500);
  }
}
