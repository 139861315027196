<nb-layout windowMode>
    <nb-layout-column>
        <img src="assets/images/powerVAS_logo.png" alt="PowerVAS Logo">

        <div class="page">
            <div class="left-column">                
                <h4>Interface d'administration</h4>
            </div>
    
            <div class="right-column" [nbSpinner]="loading" nbSpinnerStatus="primary">
                <h4 translate="signin.login"></h4>
                <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <label translate="signin.username"></label>
                        <input type="text" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && getDataForm.username.errors }"/>
                        <div *ngIf="submitted && getDataForm.username.errors" class="invalid-feedback">
                            <div *ngIf="getDataForm.username.errors.required">Username is required</div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="password" translate="signin.password"></label>
                        <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && getDataForm.password.errors }"/>

                        <div *ngIf="submitted && getDataForm.password.errors" class="invalid-feedback">
                            <div *ngIf="getDataForm.password.errors.required">Password is required</div>
                        </div>
                    </div>

                    <p class="error" *ngIf="wrongCredentials">Les identifiants renseignés sont incorrects</p>

                    <div class="form-group">
                        <button class="btn btn-primary" translate="signin.login"></button>
                    </div>
                </form>
    
                <div class="language-picker">
                    <gp-language-picker></gp-language-picker>
                </div>
            </div>
        </div>
    </nb-layout-column>
</nb-layout>    
