<div class="alert">
    <div class="row">
        <div class="col-md-9">
            {{ translations.alert.prefix }} 
            <strong>{{ translations.alert.core }}</strong> 
            {{ translations.alert.suffix }}
            <span *ngIf="user">
                <a [href]="applicationLink" target="_blank">{{ translations.common.click}}</a> {{ translations.alert.info }} <strong>#{{ user.ref }} :</strong> {{ user.first_name }} {{ user.last_name }}.
            </span>
        </div>
        <div class="col-md-3 button-container">
            <button nbButton
                size="tiny"
                shape="round"
                status="info"
                (click)="quitSimulation()"
                >{{ translations.quit }}</button>
        </div>
    </div>
</div>
<div class="placeholder"></div>