/*
 * Copyright (C) 2022 GridPocket SAS - All Rights Reserved
 * Unauthorized usage, modification or copying of this file, via any medium are strictly prohibited
 * Proprietary and confidential
 */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FeatureFAQJson, FeatureFAQListJson } from '../@types/FeatureFAQJson';
import { UnavailableServiceService } from './unavailable-service.service';

@Injectable({
  providedIn: 'root'
})
export class FAQService {
  powervasAPI = '/api/features/feature_faq';

  constructor(private httpClient: HttpClient, private unavailableServiceService: UnavailableServiceService) { }

  get() {
    return this.unavailableServiceService.handle(
      this.httpClient.get<FeatureFAQListJson>(`${this.powervasAPI}`),
      this.powervasAPI,
    );
  }

  remove(id) {
    return this.unavailableServiceService.handle(
      this.httpClient.delete(`${this.powervasAPI}/${id}`),
      this.powervasAPI,
    );
  }

  create(obj) {
    return this.unavailableServiceService.handle(
      this.httpClient.post(`${this.powervasAPI}`, obj),
      this.powervasAPI,
    );
  }

  update(item: FeatureFAQJson) {
    return this.unavailableServiceService.handle(
      this.httpClient.put<any>(`${this.powervasAPI}/${item._id}`, item),
      this.powervasAPI,
    );
  }
}
