/**
 * Copyright (C) 2023 GridPocket SAS - All Rights Reserved
 * Unauthorized usage, modification or copying of this file, via any medium are strictly prohibited
 * Proprietary and confidential
 */

import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { UnavailableServiceService } from './unavailable-service.service';
import { PriceJson, QuotesJson } from '../@types/QuotesJson';

@Injectable({
  providedIn: 'root'
})
export class QuotesService {
  searchQuotes = '/ms_quote/quotes/search';
  status = '/ms_quote/quotes/status';
  comment = '/ms_quote/quotes/comment';
  prices = '/ms_quote/quotes/prices';
  kpis = '/ms_quote/quotes/kpi/completion';
  headers: HttpHeaders;

  constructor(private httpClient: HttpClient,
              private unavailableServiceService: UnavailableServiceService) { }

  getQuotes(page: string = '1', pageSize: number = 10, headers: HttpHeaders, search?, order?, completed?, statusFilter?, tab?): Observable<HttpResponse<QuotesJson[]>> {
    if (headers) {
      this.headers = headers;
    }
    return this.unavailableServiceService.handle(
      this.httpClient.get<QuotesJson[]>(`${this.searchQuotes}?page=${page}&pageSize=${pageSize}${search?.value || statusFilter > -1 || (tab && tab !== 'all') ?
        this.prepareSeach(search, statusFilter, tab) : ''}${order ? this.getOrderBy(order): ''}${completed ? '&completed=true' : ''}`, {headers, observe: 'response'}),
      this.searchQuotes,
    );
  }

  getPrices(headers: HttpHeaders) {
    if (headers) {
      this.headers = headers;
    }
    return this.unavailableServiceService.handle(
      this.httpClient.get<any>(this.prices, {headers}), this.prices
    );
  }

  updatePrice(id: string, value: number, headers: HttpHeaders) {
    if (headers) {
      this.headers = headers;
    }
    return this.unavailableServiceService.handle(
      this.httpClient.put<PriceJson>(`${this.prices}/${id}`, {newPrice: value}, {headers}), this.prices);
  }

  updateStatus(id: string, status: number, headers: HttpHeaders) {
    if (headers) {
      this.headers = headers;
    }
    return this.unavailableServiceService.handle(
      this.httpClient.put<QuotesJson[]>(`${this.status}/${id}`, {status}, {headers}), this.status);
  }

  addComment(id: string, comment: string, headers: HttpHeaders) {
    if (headers) {
      this.headers = headers;
    }
    return this.unavailableServiceService.handle(
      this.httpClient.put<any>(`${this.comment}/${id}`, {comment}, {headers}), this.comment);
  }

  prepareSeach(search, status?, percentFilter?): string {
    const filtersArray = [];
    if (status > -1) {
      filtersArray.push(`status:${status}`);
    }
    if (search?.value) {
      const isNumber = Number.isFinite(Number(search?.value));
    if (isNumber) {
      filtersArray.push(`${search.fields[1]}:${search?.value}`);
    } else {
      filtersArray.push(`${search.fields[0]}:${search?.value}`);
    }
    }
    let percentFilterText = '';
    if (percentFilter && percentFilter !== 'all') {
      if (percentFilter === 'first') {
        percentFilterText = '&notFinished=true';
      } else {
        filtersArray.push('surveyPercent.surveyB:100');
      }
    }
    return filtersArray?.length ? `&filter=${filtersArray.join(',')}${percentFilterText}` : percentFilterText;
  }

  getOrderBy(order): string {
    let orderText = '';
    for (const key in order) {
      if (order.hasOwnProperty(key)) {
        const orderVal = order[key] > 0 ? 'asc' : 'desc';
        orderText = `&sortField=${key}&sortOrder=${orderVal}`;
      }
    }
    return orderText;
  }

  getKPI(from: string, to: string, headers: HttpHeaders) {
    if (headers) {
      this.headers = headers;
    }
    if (!this.headers) {
      return of(null);
    }
    return this.unavailableServiceService.handle(
      this.httpClient.get<any>(`${this.kpis}?from=${from}&to=${to}`, {headers: this.headers}), this.prices
    );
  }
}
