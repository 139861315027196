import { Component, OnInit, Input } from '@angular/core';
import { UserJson } from '../../../../@types/UserJson';
import { VariablesJson } from '../../../../@types/VariableJson';
import { SiteJson } from '../../../../@types/SiteJson';
import * as moment from 'moment';

@Component({
  selector: 'gp-user-details-grd',
  templateUrl: './user-details-grd.component.html',
  styleUrls: ['./user-details-grd.component.scss']
})
export class UserDetailsGrdComponent implements OnInit {
  @Input() userSites: Array<SiteJson>;
  @Input() user: UserJson;

  constructor() { }

  ngOnInit(): void {
  }

  hasDemands(site): boolean {
    return site.grd_demands_date && Object.values(site.grd_demands_date).some(e => e);
  }

  hasActiveContract(site) {
    return site.contractsTimeline.find(e => e.status === 1);
  }

  getRefPDS(site) {
    if (site.ref_pds) {
      return site.ref_pds;
    }

    if (site.contractsTimeline && site.contractsTimeline.length) {
      return site.contractsTimeline[site.contractsTimeline.length - 1].meterId;
    }

    return '-';
  }

  formatDate(dateStr) {
    const d = moment(dateStr);
    if (!dateStr || dateStr === '' || !d.isValid()) {
      return '-';
    }

    return d.format('DD/MM/YYYY hh:mm:ss');
  }
}
