import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UnavailableServiceService } from './unavailable-service.service';

@Injectable({
  providedIn: 'root'
})
export class SearchUserService {
  searchUserEndpoint = '/api/metrics/searchUser';
  searchUserTokensEndpoint = '/api/metrics/usersWithPushTokens';
  searchUserCriteriasEndpoint = '/api/metrics/searchUserFromCriterias';

  constructor(private httpClient: HttpClient, private unavailableServiceService: UnavailableServiceService) { }

  search(search, limit=5) {
    return this.unavailableServiceService.handle(
      this.httpClient.get<any>(`${this.searchUserEndpoint}?search=${search}&limit=${limit}&activeOnly=true`),
      this.searchUserEndpoint,
    );
  }

  searchUsersWithPushTokens() {
    return this.unavailableServiceService.handle(
      this.httpClient.get<any>(`${this.searchUserTokensEndpoint}`),
      this.searchUserTokensEndpoint,
    );
  }

  searchUsersFromCriterias(criterias) {
    const url = `${this.searchUserCriteriasEndpoint}?criterias=${encodeURIComponent(JSON.stringify(criterias))}`;
    return this.unavailableServiceService.handle(this.httpClient.get<any>(url), this.searchUserCriteriasEndpoint);
  }
}
