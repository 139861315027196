import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import ConfiguredEmailKeys from '../@types/ConfiguredEmailKeys';
import { UnavailableServiceService } from './unavailable-service.service';
import { DomSanitizer } from '@angular/platform-browser';
import CampaignJson from '../@types/CampaignJson';
import { MsNotificationsMessage } from '../@types/MsNotificationsMessages';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  msNotifications = '/api/notif';

  constructor(private httpClient: HttpClient,
              private unavailableServiceService: UnavailableServiceService,
              private sanitizer: DomSanitizer) { }

  /**
   * Returns configured notification keys of the notification backend service
   */
  getConfiguredNotificationKeys() {
    return this.unavailableServiceService.handle(
      this.httpClient.get<ConfiguredEmailKeys>(`${this.msNotifications}/configured-email-keys`),
      this.msNotifications,
    );
  }

  buildRenderURL(key: string, vars: Array<{ name: string; content: string }>) {
    const params = new HttpParams().set('vars', JSON.stringify(vars)).toString();
    return this.sanitizer.bypassSecurityTrustResourceUrl(`${this.msNotifications}/templates/${key}/render?${params}`);
  }

  sendCampaign(campaign: CampaignJson) {
    return this.unavailableServiceService.handle(
      this.httpClient.post<ConfiguredEmailKeys>(`${this.msNotifications}/send-campaign`, campaign),
      this.msNotifications,
    );
  }

  send(notification: MsNotificationsMessage) {
    return this.unavailableServiceService.handle(
      this.httpClient.post<ConfiguredEmailKeys>(`${this.msNotifications}/send`, notification),
      this.msNotifications,
    );
  }
}
