<h5>Profil :</h5>

<div class="user-profile">
  <div class="image-container">
    <img src="./assets/images/pp.png" alt="">
  </div>

  <div class="user-fields" *ngIf="user">
    <span class="group-badge" *ngFor="let group of userGroups" [translate]="'users.card.types.' + group.name"></span>
    <br>
    <span class="user-field"><span class="label" translate=users.card.details.fields.ref></span> #{{ user.ref || '-' }}</span>
    <br>
    <span class="user-field"><span class="label" translate=users.card.details.fields.lastname></span> {{ user.last_name || '-' }}</span>
    <span class="user-field"><span class="label" translate=users.card.details.fields.firstname></span> {{ user.first_name || '-' }}</span>
    <br>
    <span class="user-field"><span class="label" translate=users.card.details.fields.email></span> {{ user.email || '-' }}</span>
    <br>
    <span class="user-field"><span class="label" translate=users.card.details.fields.phone></span> {{ user.phone || '-' }}</span>
  </div>

  <div class="user-fields" *ngIf="user">
    <span class="user-field"><span class="label" translate=users.card.details.fields.invited></span> {{ user.invitedAtString || 'common.no' | translate }}</span>
    <br>
    <span class="user-field"><span class="label" translate=users.card.details.fields.initialized></span> {{ user.initDateString || 'common.no' | translate }}</span>
    <br>
    <span class="user-field" *ngIf="user.cgu_version"><span class="label" translate="users.card.details.fields.cgu_version"></span> {{ user.cgu_version | translate }}</span>
  </div>
</div>


<div *ngIf="userSites && userSites.length">
  <h5>{{ 'users.card.details.contracts.title' | translate }}</h5>

  <div class="contracts-item" *ngIf="userSites && userSites.length">
    <nb-list>
      <nb-list-item *ngFor="let site of userSites; let i = index" class="item" [ngClass]="site.energyType">
        <div class="item-body">
          <span>
            <img src="./assets/images/gas_icon.svg" *ngIf="site.energyType === 'gas' && hasActiveContract(site)"/>
            <img src="./assets/images/disabled_gas_icon.svg" *ngIf="site.energyType === 'gas' && !hasActiveContract(site)"/>
  
            <img src="./assets/images/elec_icon.svg" *ngIf="(!site.energyType || site.energyType === 'electricity') && hasActiveContract(site)"/>
            <img src="./assets/images/disabled_elec_icon.svg" *ngIf="(!site.energyType || site.energyType === 'electricity') && !hasActiveContract(site)"/>
          </span>


          <span>
            <span class="user-field"><span class="label" translate=users.card.details.contracts.fields.contract></span> {{ site.ref_contract || site.siteDetails.ref || '-' }}</span>
            
            <br>
            <span class="user-field"><span class="label" translate=users.card.details.contracts.fields.pdl></span> {{ site.ref_pdl || site?.edl?.ref || '-' }}</span>
            
            <br>
            <span class="user-field"><span class="label" translate=users.card.details.contracts.fields.pds></span> {{ getRefPDS(site) }}</span>
            
            <br *ngIf="site.fullAddress">
            <span class="user-field"><span class="label" *ngIf="site.fullAddress" translate=users.card.details.contracts.fields.address></span> {{ site.fullAddress }}</span>
            
            <br>
            <span class="user-field">
              <span class="label" translate=users.card.details.contracts.fields.start></span>
              {{ getSiteFirstContract(site)?.from ? (getSiteFirstContract(site).from | date: 'dd/MM/yyyy ': undefined:'fr-FR') : '-'  }}
            </span>
            
            <br>
            <span class="user-field">
              <span class="label" translate=users.card.details.contracts.fields.end></span>
              {{ getSiteLastContract(site)?.to ? (getSiteLastContract(site).to | date: 'dd/MM/yyyy ': undefined:'fr-FR') : '-' }}
            </span>
            
            <br>
            <span class="user-field"><span class="label" translate=users.card.details.contracts.fields.active></span>
              {{ hasActiveContract(site) ? 'oui' : 'non' }}</span>
          </span>


          <span *ngIf="getSiteLastContract(site)">
            <span class="user-field" *ngIf="getSiteLastContract(site).pricingPeriods"><span class="label" translate=users.card.details.contracts.fields.repartition></span> {{ getSiteLastContract(site).pricingPeriods === '1' ? 'Base' : 'Heures pleines / heures creuses' || '-' }}</span>
            <br>
            <span class="user-field" *ngIf="hasContract(site) && getSiteLastContract(site).power">
              <span class="label" translate="users.card.details.contracts.fields.power"></span> {{ getCurrentContractPower(site) + 'kva' || '' }}
            </span>
            <br>
            <span class="user-field"><span class="label" translate="users.card.details.contracts.fields.issmart"></span> {{ isAMM(site) ? 'oui' : 'non' }}</span>
            <br>
            <span class="user-field" *ngIf="hasContract(site) && getSiteLastContract(site).billing_mode"><span class="label" translate=users.card.details.contracts.fields.payment></span> {{ getSiteLastContract(site).billing_mode }}</span>
            <br *ngIf="getSiteLastContract(site).billing_mode">
            <span class="user-field" *ngIf="hasContract(site) && getSiteLastContract(site).billingType"><span class="label" translate=users.card.details.contracts.fields.billingType></span> {{ getSiteLastContract(site).billingType }}</span>
          </span>
        </div>
  

        <div class="survey-overview" *ngIf="getContractSurvey(site) && site.energyType=== 'electricity'">
          <span nbTooltip="{{ 'users.card.details.tooltips.inhabitantsPrefix' | translate }} {{ getContractSurvey(site).people }} {{ 'users.card.details.tooltips.inhabitantsSuffix' | translate }}">
            <fa-icon [icon]="faPeopleIcon"></fa-icon> x {{ getContractSurvey(site).people }}
          </span>
          <span *ngIf="getContractSurvey(site).isHouse" [nbTooltip]="'users.card.details.tooltips.house' | translate">
            <fa-icon [icon]="faHouseIcon"></fa-icon>
          </span>
          <span *ngIf="!getContractSurvey(site).isHouse" [nbTooltip]="'users.card.details.tooltips.flat' | translate">
            <fa-icon [icon]="faBuildingIcon"></fa-icon>
          </span>
          <span *ngIf="!getContractSurvey(site).isPrincipal" [nbTooltip]="'users.card.details.tooltips.secondary' | translate">
            <fa-icon [icon]="faSecondaryHouseIcon"></fa-icon>
          </span>
          <span *ngIf="getContractSurvey(site).isPrincipal" [nbTooltip]="'users.card.details.tooltips.principal' | translate">
            <fa-icon [icon]="faPrincipalHouseIcon"></fa-icon>
          </span>
          <span *ngIf="getContractSurvey(site).hasElecHeating" [nbTooltip]="'users.card.details.tooltips.heating' | translate">
            <fa-icon [icon]="faHeatingIcon"></fa-icon>
          </span>
          <span *ngIf="getContractSurvey(site).hasClim" [nbTooltip]="'users.card.details.tooltips.clim' | translate">
            <fa-icon [icon]="faClimIcon"></fa-icon>
          </span>
          <span *ngIf="getContractSurvey(site).hasElecECS" [nbTooltip]="'users.card.details.tooltips.ecs' | translate">
            <fa-icon [icon]="faShowerIcon"></fa-icon>
          </span>
          <span *ngIf="getContractSurvey(site).hasElecCooking" [nbTooltip]="'users.card.details.tooltips.cooking' | translate">
            <fa-icon [icon]="faCookingIcon"></fa-icon>
          </span>
        </div>
  
        
        <div class="survey-overview" *ngIf="getContractSurvey(site) && site.energyType === 'gas'">
          <span nbTooltip="{{ 'users.card.details.tooltips.inhabitantsPrefix' | translate }} {{ getContractSurvey(site).people }} {{ 'users.card.details.tooltips.inhabitantsSuffix' | translate }}">
            <fa-icon [icon]="faPeopleIcon"></fa-icon> x {{ getContractSurvey(site).people }}
          </span>
          <span *ngIf="getContractSurvey(site).isHouse" [nbTooltip]="'users.card.details.tooltips.house' | translate">
            <fa-icon [icon]="faHouseIcon"></fa-icon>
          </span>
          <span *ngIf="!getContractSurvey(site).isHouse" [nbTooltip]="'users.card.details.tooltips.flat' | translate">
            <fa-icon [icon]="faBuildingIcon"></fa-icon>
          </span>
          <span *ngIf="!getContractSurvey(site).isPrincipal" [nbTooltip]="'users.card.details.tooltips.secondary' | translate">
            <fa-icon [icon]="faSecondaryHouseIcon"></fa-icon>
          </span>
          <span *ngIf="getContractSurvey(site).isPrincipal" [nbTooltip]="'users.card.details.tooltips.principal' | translate">
            <fa-icon [icon]="faPrincipalHouseIcon"></fa-icon>
          </span>
          <span *ngIf="getContractSurvey(site).hasGasHeating" [nbTooltip]="'users.card.details.tooltips.heating' | translate">
            <fa-icon [icon]="faHeatingIcon"></fa-icon>
          </span>
          <span *ngIf="getContractSurvey(site).hasGasECS" [nbTooltip]="'users.card.details.tooltips.ecs' | translate">
            <fa-icon [icon]="faShowerIcon"></fa-icon>
          </span>
          <span *ngIf="getContractSurvey(site).hasGasCooking" [nbTooltip]="'users.card.details.tooltips.cooking' | translate">
            <fa-icon [icon]="faCookingIcon"></fa-icon>
          </span>
        </div>
      </nb-list-item>
    </nb-list>
  </div>
</div>