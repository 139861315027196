/**
 * Copyright (C) 2022 GridPocket SAS - All Rights Reserved
 * Unauthorized usage, modification or copying of this file, via any medium are strictly prohibited
 * Proprietary and confidential
 */


import { Injectable } from '@angular/core';
import { QuoteDetailsModalComponent } from '../../shared/components/quote-details-modal/quote-details-modal.component';
import { NbDialogService } from '@nebular/theme';

@Injectable({
  providedIn: 'root'
})
export class QuoteDetailsModalService {
  constructor(private dialogService: NbDialogService) {}

  open(quote, headers, showComments, addComment) {
    const dialogRef = this.dialogService.open(QuoteDetailsModalComponent, { context: { quote, headers, showComments, addComment } });
    return dialogRef.onClose;
  }

}
