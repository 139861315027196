<div *ngIf="userSites && userSites.length">
    <h5>Dernières demandes GRD par contrat :</h5>
    <div class="contracts-item" *ngIf="userSites && userSites.length">
      <nb-list>
        <nb-list-item *ngFor="let site of userSites; let i = index" class="item" [ngClass]="site.energyType">
          <div class="item-body">
            <span>
              <img src="./assets/images/gas_icon.svg" *ngIf="site.energyType === 'gas' && hasActiveContract(site)"/>
              <img src="./assets/images/disabled_gas_icon.svg" *ngIf="site.energyType === 'gas' && !hasActiveContract(site)"/>
    
              <img src="./assets/images/elec_icon.svg" *ngIf="site.energyType === 'electricity' && hasActiveContract(site)"/>
              <img src="./assets/images/disabled_elec_icon.svg" *ngIf="site.energyType === 'electricity' && !hasActiveContract(site)"/>
            </span>

            <span>
              <span class="user-field"><span class="label" translate=users.card.details.contracts.fields.contract></span> {{ site.ref_contract || '-' }}</span>
              <br>
              <span class="user-field"><span class="label" translate=users.card.details.contracts.fields.pdl></span> {{ site.ref_pdl || site.edl.ref || '-' }}</span>
              <br>
              <span class="user-field"><span class="label" translate=users.card.details.contracts.fields.pds></span> {{ getRefPDS(site) }}</span>
              <br>
              <span class="user-field"><span class="label" translate=users.card.details.contracts.fields.active></span>
                {{ hasActiveContract(site) ? 'oui' : 'non' }}</span>
            </span>

            <span *ngIf="hasDemands(site)">
              <div *ngIf="site.energyType === 'electricity'">
                <span class="user-field" >
                    <span class="label" translate=users.card.grd.elec.technicaldata></span> {{ formatDate(site.grd_demands_date.REQUESTS_ELECTRICITY_TECHNICAL_DATA) || '-' }}
                </span>
                <br>
                <span class="user-field">
                    <span class="label" translate=users.card.grd.elec.loadcurve></span> {{ formatDate(site.grd_demands_date.REQUESTS_ELECTRICITY_LOAD_CURVE) || '-' }}
                </span>
                <br>
                <span class="user-field">
                    <span class="label" translate=users.card.grd.elec.indexes></span> {{ formatDate(site.grd_demands_date.REQUESTS_ELECTRICITY_INDEXES) || '-' }}
                </span>
                <br>
                <span class="user-field">
                    <span class="label" translate=users.card.grd.elec.revokeall></span> {{ formatDate(site.grd_demands_date.REVOKE_ALL_ELECTRICITY) || '-' }}
                </span>
                <br>
                <span class="user-field">
                    <span class="label" translate=users.card.grd.elec.revokeloadcurve></span> {{ formatDate(site.grd_demands_date.REVOKE_LOAD_ELECTRICITY) || '-' }}
                </span>
                <br>
                <span class="user-field">
                    <span class="label" translate=users.card.grd.elec.revokeindexes></span> {{ formatDate(site.grd_demands_date.REVOKE_IDX_ELECTRICITY) || '-' }}
                </span>
              </div>

              <div *ngIf="site.energyType === 'gas'">
                <span class="user-field" >
                    <span class="label" translate=users.card.grd.gas.consumption></span> {{ formatDate(site.grd_demands_date.REQUESTS_GAZ_CONSUMPTION) || '-' }}
                </span>
                <br>
                <span class="user-field">
                    <span class="label" translate=users.card.grd.gas.revokeconsumption></span> {{ formatDate(site.grd_demands_date.REVOKE_GAZ_CONSUMPTION) || '-' }}
                </span>
              </div>
            </span>

            <span *ngIf="!hasDemands(site)">
                {{ 'users.card.grd.nodemand' | translate }}
            </span>
          </div>
        </nb-list-item>
      </nb-list>
    </div>
  </div>