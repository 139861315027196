/**
 * Copyright (C) 2021 GridPocket SAS - All Rights Reserved
 * Unauthorized usage, modification or copying of this file, via any medium are strictly prohibited
 * Proprietary and confidential
 */


export class Traces {
    data: Array<Trace>;
}

export class Trace {
    _id: string;
    creationDate: string;
    refClient: string;
    action: string;
    observation: string;
}

export interface Order {
    [name: string]: AscDesc;
}

export enum AscDesc {
    ASC = 1,
    DESC = -1
}

export enum DirectionNames {
    ASCENDING = 'asc',
    DESCENDING = 'desc'
}
