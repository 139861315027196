<span class="i18n" *ngIf="visible">
    <button nbButton ghost size="small" (click)="toggleLanguageSelectorVisibility()">
      <span class="mr-2 flag-icon flag-icon-{{ selectedLanguage }}"></span>
    </button>

    <nb-select [style]="{display: languageSelectorVisibility ? 'block' : 'none'}"
                [(selected)]="selectedLanguage"
                (selectedChange)="onLanguageChange()"
                size="tiny">
        <nb-option *ngFor="let language of languages" [value]="language.value">
          <span class="mr-2 flag-icon flag-icon-{{ language.value }}"></span> {{ language.label }}
        </nb-option>
    </nb-select>
</span>
