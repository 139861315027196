import { Injectable, EventEmitter } from '@angular/core';
import UnavailableServiceInfo from '../@types/UnavailableServiceInfo';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UnavailableServiceService {

  emitter: EventEmitter<UnavailableServiceInfo> = new EventEmitter<UnavailableServiceInfo>();

  constructor() {}

  /**
   * Emit an UnavailableService event
   *
   * @param info Error information
   */
  emit(info: UnavailableServiceInfo) {
    this.emitter.emit(info);
  }

  /**
   * Subscribe to the EventEmitter
   *
   * @param fn subscription function
   */
  subcribe(fn) {
    return this.emitter.subscribe(fn);
  }

  /**
   * Handle service access with emition of a UnavailableService event if an error occured
   */
  handle<T>(obs: Observable<T>, serviceName): Observable<T> {
    return obs.pipe(
      catchError((err) => {
        this.emit({ error: err, serviceName });
        return throwError(err);
      })
    );
  }
}
