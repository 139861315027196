import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FeaturesMandatesJson } from '../@types/FeaturesMandatesJson';
import { UnavailableServiceService } from './unavailable-service.service';

@Injectable({
  providedIn: 'root'
})
export class MandatesService {
  featureMandates = '/api/features/feature_mandate';

  constructor(private httpClient: HttpClient,
              private unavailableServiceService: UnavailableServiceService) { }

  getMandates() {
    return this.unavailableServiceService.handle(
      this.httpClient.get<FeaturesMandatesJson>(`${this.featureMandates}`),
      this.featureMandates,
    );
  }
}
