<div class="d-flex justify-content-center">
    <button
    nbButton
    size="tiny"
    status="primary"
    (click)="displayPreviousPage()">
        <nb-icon icon="arrow-left"></nb-icon>
    </button>

    <button class="ml-1"
    nbButton
    size="tiny"
    *ngFor="let page of pages; let pageIndex = index"
    [status]="pages[pageIndex] === currentPageString ? 'basic' : 'primary'"
    (click)="paginationHandler(pageIndex)">
        {{ page }}
    </button>


    <button class="ml-1"
    nbButton
    size="tiny"
    status="primary"
    (click)="displayNextPage()">
        <nb-icon icon="arrow-right"></nb-icon>
    </button>
</div>
  