import {Injectable, OnInit} from '@angular/core';
import {AppConfig} from '../../services/app-config.service';

declare const grecaptcha: any;

@Injectable({
    providedIn: 'root'
})
export class CaptchaManagerService {
    constructor(private appConfig: AppConfig,
    ) {
    }

    async getCaptcha(action: string): Promise<string> {
        if (this.appConfig.get('recaptchaKey')) {

            return grecaptcha.execute(
                this.appConfig.get('recaptchaKey'),
                {action: 'submit'},
            );
        } else {
            return new Promise((resolve) => {
                resolve('');
            });
        }
    }
}
