/**
 * Copyright (C) 2023 GridPocket SAS - All Rights Reserved
 * Unauthorized usage, modification or copying of this file, via any medium are strictly prohibited
 * Proprietary and confidential
 */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UnavailableServiceService } from './unavailable-service.service';

@Injectable({
  providedIn: 'root'
})
export class ErlService {
  constructor(private httpClient: HttpClient, private unavailableServiceService: UnavailableServiceService) { }

  getList(length: number, skip: number): Observable<{
    data: Array<any>;
    recordsTotal: number;
    recordsFiltered: number;
  }> {
    return this.unavailableServiceService.handle(
      this.httpClient.get<{
        data: Array<any>;
        recordsTotal: number;
        recordsFiltered: number;
      }>(`/erl/analytics?skip=${skip}&length=${length}`),
      '/erl',
    );
  }
}
