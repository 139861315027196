import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { VariablesJson } from '../../../../@types/VariableJson';
import { SiteJson } from '../../../../@types/SiteJson';
import { UserService } from '../../../../services/user.service';
import { GroupJson } from '../../../../@types/GroupJson';
import { UserDisplayJson } from '../../../../@types/UserDisplayJson';
import { UtilityRequestsService } from '../../../../services/requests.service';
import { UtilityRequestsJson } from '../../../../@types/UtilityRequestsJson';

@Component({
  selector: 'gp-user-details-open-requests',
  templateUrl: './user-open-requests.component.html',
  styleUrls: ['./user-open-requests.component.scss'],
})
export class UserOpenRequestsComponent implements OnInit {
  @Input() userSites: Array<SiteJson>;
  @Input() user: UserDisplayJson;
  @Input() userGroups: Array<GroupJson>;
  requests: UtilityRequestsJson[];
  requestOpen = false;
  requestOpened: UtilityRequestsJson;
  requestError = false;

  constructor(
    private userService: UserService,
    private requestsService: UtilityRequestsService
  ) {}

  ngOnInit() {
    this.requestsService.getRequests(this.user.ref, 'ACTIVE').subscribe((requests) => {
      this.requests = requests.data;
      for (const req of this.requests) {
        if (req.creationDate) {
          const creationDate = new Date(req.creationDate);
          req.creationDate = creationDate;
        }
        if (req.closingDate) {
          const closingDate = new Date(req.closingDate);
          req.closingDate = closingDate;
        }
        if (req.requestId === 'E') {
          this.requestError = true;
        }
      }
      this.requests.sort((a, b) => (a.creationDate < b.creationDate) ? 1 : ((b.creationDate < a.creationDate) ? -1 : 0));
    });
  }

  openRequest(request) {
    this.requestOpen = true;
    this.requestOpened = request;
  }

  closeRequest() {
    this.requestOpen = false;
  }

}
