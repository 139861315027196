<form [formGroup]="form" (ngSubmit)="generateLink()">
  Référence contrat :
  <input type="text" id="ref" nbInput fullWidth formControlName="ref"/>

  Fonctionnalité cible :
  <nb-select fullWidth formControlName="feature">
    <nb-option value="/invoices">Paiement en ligne</nb-option>
    <nb-option value="/requests/einvoice_subscription">Souscription à l’E-facture</nb-option>
    <nb-option value="/requests/manage_direct_debit">Souscription au prélèvement automatique</nb-option>
    <nb-option value="/alerts">Pages alertes</nb-option>
    <nb-option value="/survey">Profil énergétique</nb-option>
    <nb-option value="/profile/info">Profil utilisateur</nb-option>
  </nb-select>

  <button nbButton status="primary">Générer</button>
</form>

<div *ngIf="link">
  Lien généré :
  <div class="copy-link">
    <input type="text" class="copy-link-input" id="link" nbInput fullWidth [value]="link" (click)="selectLink()" readonly/>
    <button type="button" class="copy-link-button" (click)="copyLink()">
      <nb-icon icon="copy-outline"></nb-icon>
    </button>
  </div>
</div>
