import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'gp-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private router: Router,
  ) {}

  async ngOnInit() {
    const authenticated = await this.authService.isLoggedIn().toPromise();

    // If user is authenticated, redirect to its first landing-page
    if(authenticated) {
      const userGroups = await this.userService.getUserGroups('current').toPromise();
      const url = userGroups.groups.find(e => e.admin).landingPage;
      this.router.navigate([url]);
    } else {
      // If user is not authenticated, redirect to signin page
      this.router.navigate(['/signin']);
    }
  }
}
