<nb-card>
  <nb-card-header>
    <div class="row" *ngIf="user">
      <div class="col-md-6">
        {{ 'users.card.title' | translate }} {{ user && user.first_name || '' }} {{ user && user.last_name || '' }}
      </div>
      <div class="col-md-6 right-buttons">
        <button nbButton
          ghost
          nbTooltip="{{ 'users.card.details.simulation' | translate }} {{ user.first_name || '-' }} {{ user.last_name || '-'}}"
          (click)="simulateUser()">
          <nb-icon icon="eye-outline"></nb-icon>
        </button>

        <button nbButton ghost nbTooltip="Fermer" (click)="close()">
          <nb-icon icon="close-outline" pack="eva"></nb-icon>
        </button>
      </div>
    </div>
  </nb-card-header>

  <nb-card-body>
    <nb-tabset [nbSpinner]="loading"
      nbSpinnerSize="large"
      nbSpinnerStatus="primary">

      <nb-tab [tabTitle]="'users.card.tabs.profile' | translate">
        <gp-user-details *ngIf="!loading && user" [userSites]="userSites" [user]="user" [userGroups]="userGroups"></gp-user-details>
      </nb-tab>

      <nb-tab [tabTitle]="'users.card.tabs.grd' | translate" *ngIf="options.userDetailsGRD">
        <gp-user-details-grd *ngIf="!loading && user" [userSites]="userSites" [user]="user"></gp-user-details-grd>
      </nb-tab>

      <nb-tab [tabTitle]="'users.card.tabs.rgpd' | translate" *ngIf="options.userDetailsGDPR">
        <gp-user-details-rgpd *ngIf="!loading && user" [userSites]="userSites" [user]="user"></gp-user-details-rgpd>
      </nb-tab>

      <nb-tab [tabTitle]="'users.card.tabs.ecotroks' | translate" *ngIf="ecotroksVisible">
        <gp-user-details-ecotroks *ngIf="!loading && user" [user]="user"></gp-user-details-ecotroks>
      </nb-tab>

      <nb-tab [tabTitle]="'users.card.tabs.open-requests' | translate" *ngIf="options.userDetailsGrdDemands">
        <gp-user-details-open-requests *ngIf="!loading && user" [userSites]="userSites" [user]="user" [userGroups]="userGroups"></gp-user-details-open-requests>
      </nb-tab>

      <nb-tab [tabTitle]="'users.card.tabs.closed-requests' | translate" *ngIf="options.userDetailsGrdDemands">
        <gp-user-details-closed-requests *ngIf="!loading && user" [userSites]="userSites" [user]="user" [userGroups]="userGroups"></gp-user-details-closed-requests>
      </nb-tab>

      <nb-tab [tabTitle]="'users.card.tabs.link-generation' | translate" *ngIf="isLinkGenerationEnabled()">
        <gp-user-link-generation *ngIf="!loading && user" [userSites]="userSites" [user]="user"></gp-user-link-generation>
      </nb-tab>
    </nb-tabset>
  </nb-card-body>
</nb-card>
