import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import UploadResponseJson from '../@types/UploadResponseJson';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  constructor(private httpClient: HttpClient) { }

  uploadFile(file: File): Observable<UploadResponseJson> {
    const form = new FormData();
    form.append('file', file);
    return this.httpClient.post<UploadResponseJson>('/uploads', form);
  }
}
