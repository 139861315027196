import { Component, OnInit, Input } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { SiteJson } from '../../../@types/SiteJson';
import { GroupJson } from '../../../@types/GroupJson';

import * as moment from 'moment';

import { VariablesJson } from '../../../@types/VariableJson';
import { UserDisplayJson } from '../../../@types/UserDisplayJson';
import { NbDialogRef } from '@nebular/theme';
import { SimulationModeService } from '../../services/simulation-mode.service';

@Component({
  selector: 'gp-user-details-modal',
  templateUrl: './user-details-modal.component.html',
  styleUrls: ['./user-details-modal.component.scss'],
})
export class UsersDetailsModalComponent implements OnInit {
  @Input() userID: string | null = null;

  options: { [key: string]: any } = {};

  loading = true;
  pageFeatureKey = 'crm.users';

  userDetailsLinkGenerationGroups = ['user'];

  user: UserDisplayJson;
  userSites: Array<SiteJson>;
  userGroups: Array<GroupJson>;

  ecotroksVisible = false;

  constructor(
    private backend: UserService,
    protected ref: NbDialogRef<UsersDetailsModalComponent>,
    private simulationModeService: SimulationModeService,
    private usersBackend: UserService) {}

  ngOnInit() {
    this.loadUserData();
    this.backend.getUserGroupFeaturesKeys('current').subscribe((featureSet) => {
      this.ecotroksVisible = featureSet.has('crm.ecotroks.shop') || featureSet.has('crm.ecotroks.orders');
    });
  }

  async loadUserData() {
    const [options, { users }, sitesData, { groups }] = await Promise.all([
      this.usersBackend.getUserGroupFeatureOptions('current', this.pageFeatureKey).toPromise(),
      this.backend.getUser(this.userID).toPromise(),
      this.backend.getUserSites(this.userID).toPromise(),
      this.backend.getUserGroups(this.userID).toPromise()
    ]);

    if (!users.length) {
      return;
    }
    this.user = { ...users[0], initDateString: '', invitedAtString: '' };
    this.userGroups = groups;

    this.options = options;
    if (this.options.userDetailsLinkGenerationGroups) {
      this.userDetailsLinkGenerationGroups = this.options.userDetailsLinkGenerationGroups;
    }

    if (this.user.initDate) {
      this.user.initDateString = moment(new Date(this.user.initDate)).format('L');
    }

    if (this.user.invitedAt || this.user.invitation_date) {
      this.user.invitedAtString = moment(new Date(this.user.invitedAt || this.user.invitation_date)).format(
        'L',
      );
    }

    if (this.user.s && this.user.s.length) {
      this.userSites = sitesData.sites.sort((a, b) => {
        const aStatus = (a.contractsTimeline && a.contractsTimeline.length)
          ? a.contractsTimeline[a.contractsTimeline.length - 1].status : 4;
        const bStatus = (b.contractsTimeline && b.contractsTimeline.length)
          ? b.contractsTimeline[b.contractsTimeline.length - 1].status : 4;
        return aStatus - bStatus;
      });
    }

    this.loading = false;
  }

  async simulateUser() {
    await this.simulationModeService.enable(this.user._id, true);
  }

  isLinkGenerationEnabled() {
    return this.options.userDetailsLinkGeneration
      && this.userGroups && this.userGroups.some(group => this.userDetailsLinkGenerationGroups.includes(group.name));
  }

  close() {
    console.log('CLOSE');
    this.ref.close();
  }
}
