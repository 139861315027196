import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'gp-simulation-error-message',
  templateUrl: './simulation-error-message.component.html',
  styleUrls: ['./simulation-error-message.component.scss']
})
export class SimulationErrorMessageComponent implements OnInit {
  @Input() title: string;
  @Input() message: string;

  constructor(protected dialogRef: NbDialogRef<SimulationErrorMessageComponent>) { }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
  }
}
