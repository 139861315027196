import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'gp-pagination',
  templateUrl: './pagination.component.html'
})
export class PaginationComponent implements OnInit {

  @Input() pageSize: number;
  @Output() switchPage: EventEmitter<any> = new EventEmitter();

  pagesAmount  = 1;
  currentPage  = 1;
  currentPageString = '1';
  pages: Array<string> = ['1'];

  constructor() { }

  ngOnInit(): void {
  }

  changePagesAmount(newPagesAmount) {
    this.pagesAmount = newPagesAmount;
    this.updatePagination();
  }

  updatePagination() {
    this.pages = [];

    if (this.pagesAmount > 7) {
      if (this.currentPage > 4) {
        if (this.currentPage > this.pagesAmount - 4) {
          this.pages = [
            '1',
            '...',
            `${this.pagesAmount - 4}`,
            `${this.pagesAmount - 3}`,
            `${this.pagesAmount - 2}`,
            `${this.pagesAmount - 1}`,
            `${this.pagesAmount}`,
          ];
        } else {
          this.pages = ['1', '...', `${this.currentPage - 1}`, String(this.currentPage)];

          if (this.currentPage < this.pagesAmount) {
            this.pages = [...this.pages, `${this.currentPage + 1}`];

            if (this.currentPage + 2 < this.pagesAmount) {
              this.pages = [...this.pages, '...', `${this.pagesAmount}`];
            } else {
              this.pages = [...this.pages, `${this.currentPage + 2}`];
            }
          }
        }
      } else {
        this.pages = ['1', '2', '3', '4', '5', '...', `${this.pagesAmount}`];
      }
    } else {
      for (let i = 1; i <= this.pagesAmount; i++) {
        this.pages.push(`${i}`);
      }
    }
  }

  displayPage(newPage: number) {
    this.currentPage = newPage;
    this.currentPageString = String(newPage);
    this.updatePagination();
    this.switchPage.emit({page: this.currentPage});
  }

  displayPreviousPage() {
    if (this.currentPage > 1) {
      this.displayPage(this.currentPage - 1);
    }
  }

  displayNextPage() {
    if (this.currentPage < this.pagesAmount) {
      this.displayPage(this.currentPage + 1);
    }
  }

  paginationHandler(pageIndex: number) {
    if (pageIndex < this.pages.length) {
      if (this.pages[pageIndex] !== '...') { this.displayPage(Number(this.pages[pageIndex])); }
    }
  }

}
