/*
 * Copyright (C) 2022 GridPocket SAS - All Rights Reserved
 * Unauthorized usage, modification or copying of this file, via any medium are strictly prohibited
 * Proprietary and confidential
 */

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UtilityRequestsJson } from '../@types/UtilityRequestsJson';
import { UnavailableServiceService } from './unavailable-service.service';

@Injectable({
  providedIn: 'root'
})
export class UtilityRequestsService {

  constructor(private httpClient: HttpClient,
              private unavailableServiceService: UnavailableServiceService) { }

  getRequests(partner_id, req_state) {
    const body = new URLSearchParams();
      body.set('partnerId', partner_id);
      body.set('activeState', req_state);
    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };
    return this.unavailableServiceService.handle(
      this.httpClient.post<{
        message: string;
        data: UtilityRequestsJson[];
      }>(`/api/billing/getRequestByPartner`, body.toString(), options),
      `/api/billing/getRequestByPartner`
    );
  }
}
