import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import {SigninComponent} from './signin/signin/signin.component';
import {SigninExtComponent} from './signin/signin-ext/signin-ext.component';
import { LandingPageComponent } from './landing-page/landing-page.component';

const routes: Routes = [
  {
    path: 'signin',
    pathMatch: 'full',
    component: SigninComponent
  },
  {
    path: 'signin-ext',
    pathMatch: 'full',
    component: SigninExtComponent
  },
  {
    path: 'landing-page',
    pathMatch: 'full',
    component: LandingPageComponent
  },
  { // Temp: facilitate transition from /adminv2 prefix to no prefix
    path: 'adminv2/landing-page',
    pathMatch: 'full',
    component: LandingPageComponent
  },
  {
    path: 'pages',
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'landing-page'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
