import {Injectable} from '@angular/core';
import {HttpInterceptor, HttpRequest, HttpHandler} from '@angular/common/http';
import {from} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {CaptchaManagerService} from './captcha-manager.service';

@Injectable({
    providedIn: 'root',
})
export class CaptchaInterceptor implements HttpInterceptor {

    constructor(private captchaManagerService: CaptchaManagerService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        if (['POST', 'PUT'].includes(req.method)) {
            const action = '';
            return from(this.captchaManagerService.getCaptcha(action)).pipe(
                mergeMap((token: string) => {
                    if (token.length) {
                        const modifiedReq = req.clone({
                            setHeaders: {
                                captchakey: token
                            }
                        });
                        return next.handle(modifiedReq);
                    }
                })
            );
        }

        return next.handle(req);
    }
}
