import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FeatureBrochureJson, FeatureBrochuresJson } from '../@types/FeaturesBrochureJson';
import { UnavailableServiceService } from './unavailable-service.service';

@Injectable({
  providedIn: 'root'
})
export class MarketingDocumentsService {
  featureBrochure = '/api/features/feature_brochure';

  constructor(private httpClient: HttpClient,
              private unavailableServiceService: UnavailableServiceService) { }

  getBrochures() {
    return this.unavailableServiceService.handle(
      this.httpClient.get<FeatureBrochuresJson>(`${this.featureBrochure}`),
      this.featureBrochure,
    );
  }

  createBrochure(brochure: FeatureBrochureJson) {
    return this.unavailableServiceService.handle(
      this.httpClient.post<any>(`${this.featureBrochure}`, brochure),
      this.featureBrochure,
    );
  }

  updateBrochure(brochure: FeatureBrochureJson) {
    return this.unavailableServiceService.handle(
      this.httpClient.put<any>(`${this.featureBrochure}/${brochure._id}`, brochure),
      this.featureBrochure,
    );
  }

  removeBrochure(id) {
    return this.unavailableServiceService.handle(
      this.httpClient.delete<any>(`${this.featureBrochure}/${id}`),
      this.featureBrochure,
    );
  }
}
