export interface Step {
  title: string;
  question?: string;
  subQuestion?: string;
}

export const ExcludedNames = [
  "comments",
  "consents",
  "createdAt",
  "ref",
  "status",
  "surveyB",
  "surveyW",
  "surveyPercent",
  "updatedAt",
  "__v",
  "_id",
  "customer",
  "creationDate",
  "emailFollowUp",
  "createdAt",
  "quoteResult",
  "quoteBudget",
  "unsubscribe",
];

export enum BackendNames {
  AREA = "area",
  PROPORTION = "heatingOrCoolingProportion",
  PRICE = "energyPrice",
}

export interface Question {
  title: string;
  question?: string;
  subQuestion?: string;
  specialText?: string;
  type: QuestionTypes;
  settings: any;
  info?: string;
  prev?: boolean;
  next?: boolean;
  selectedStage: number;
  answer?: any;
  topInfo?: string;
  condition?: Conditions;
  conditionValue?: ConditionValue;
  conditionAffects?: ConditionAffect[];
  conditionForOthers?: ConditionAffect;
  secondCondition?: ConditionValue;
  number: number;
  backend_name?: string;
  energyType?: EnergyType | EnergyTypeSecond;
  userInfo?: boolean;
  titleType?: TitleType;
  answer_text?: string;
  canBeOmitted?: boolean;
  defaultAnswer?: any;
  backend_value?: BackendNames;
}

export enum TitleType {
  USER_INFO = "Vos informations",
  COMPANY_INFO = "Votre entreprise",
  ECONOMY = "Définissez votre potentiel d'économies",
  BUDGET = "Définissez votre budget",
}

export interface Stage {
  label: string;
  subLabels?: SubLabel[];
  number: number;
  subLabelsRange?: {
    from: number;
    to: number;
  };
}

export interface SubLabel {
  label: string;
  number: number;
}

export enum QuestionTypes {
  SLIDER = "slider",
  INPUT = "input",
  INPUTS = "inputs",
  CHOOSE = "choose",
  IMAGE_CHOOSE = "image_choose",
  SELECTOR = "selector",
  NUMBER = "number",
  TEXT = "text",
}

export enum Settings {
  "slider" = "SliderSettings",
  "input" = "InputSettings",
  "inputs" = "InputSettings[]",
  "choose" = "ChooseSettings",
  "image_choose" = "ImageChooseSettings",
  "selector" = "SelectorSettings[]",
}

export interface SliderSettings {
  min: number;
  max: number;
  step: number;
  unit: string;
  labels?: {
    min: string;
    max: string;
  };
  defaultValue?: number;
}

export interface InputSettings {
  label: string;
  unit?: string;
  type?: InputType;
  required?: boolean;
  name?: string;
  answer?: any;
  error?: string;
  optional?: boolean;
  summary_text?: string;
  placeholder?: string;
  backend_name?: string;
  selectorOptions?: selectorOption[];
  answer_text?: answerTextObject;
  defaultAnswer?: any;
}

export interface answerTextObject {
  [key: string]: string;
}

export interface ChooseSettings {
  options: ChooserOptions[];
  required?: boolean;
  multiOption?: boolean;
  error?: string;
}

export interface ChooserOptions {
  text: string;
  value: string;
}

export interface ImageChooserOptions extends ChooserOptions {
  image: string;
}

export interface ImageChooseSettings extends ChooseSettings {
  options: ImageChooserOptions[];
}

export interface SelectorSettings {
  image?: string;
  text?: string;
  selectorOptions: selectorOption[];
  name?: string;
  answer?: string;
  required?: boolean;
  error?: string;
}

export interface selectorOption {
  text: string;
  value: string;
}

export enum InputType {
  TEXT = "text",
  EMAIL = "email",
  PASSWORD = "password",
  CHECKBOX = "checkbox",
  VALUE = "value",
  POSTAL = "postal",
  SELECTOR = "selector",
}

export enum Conditions {
  LAST_YES = "last_yes",
  LAST_ANSWERED = "last_answered",
  NO_CONDITION = "no_condition",
  QUESTION_ANSWERED = "question_answered",
  QUESTION_ANSWERED_AND_NOT = "question_answered_and_not",
}

export interface ConditionValue {
  question?: number;
  value?: string;
  which?: number;
}

export enum EnergyType {
  HEATING = "heating",
  AIR_CONDITIONER = "air_conditioner",
  HOT_WATER = "hot_water",
  LIGHTNING = "lightning",
  ENERGY_CONSUMPTION = "energy_consumption",
}

export enum EnergyTypeSecond {
  HEATING = "heating",
  LIGHTNING = "lightning",
  DOMESTIC_HOT_WATER = "domesticHotWater",
  VMC = "VMC",
  AIR_CONDITIONER = "air_conditioner",
}

export const EnergyTypeNames = {
  [EnergyType.HEATING]: "HeatingSystem",
  [EnergyType.AIR_CONDITIONER]: "AirConditioning",
  [EnergyType.HOT_WATER]: "HotWaterSystem",
  [EnergyType.LIGHTNING]: "LightingSystem",
  [EnergyType.ENERGY_CONSUMPTION]:
    "EnergyConsumptionMonitoringAndCommunicationSystem",
};

export const EnergyTypeNamesSecond = {
  [EnergyTypeSecond.HEATING]: "Heating",
  [EnergyTypeSecond.LIGHTNING]: "Lighting",
  [EnergyTypeSecond.DOMESTIC_HOT_WATER]: "DomesticHotWater",
  [EnergyTypeSecond.VMC]: "VMC",
  [EnergyTypeSecond.AIR_CONDITIONER]: "AirConditioning",
};

export enum Popups {
  MENTION_LEGALES = "mention_legales",
  PRIVACY_POLICY = "privacy_policy",
  GENERAL_CONDITIONS = "general_conditions",
  CONFIRMATION = "confirmation",
  CONTACT = "contact",
}

export interface SummaryTableEntry {
  range?: {
    from: number;
    to: number;
  };
  title: string;
  rows: any[];
  number: number;
}

export type IPopUp = {
  name: string;
  title?: string;
  content?: string;
  actions: IActions[] | string;
  icon?: string;
  hideClose?: boolean;
  extraClass?: boolean;
};

export type IPopUpConfig = {
  config: IPopUp[];
};

export type IActions = {
  method: string;
  class: string;
  text: string;
};

export type ICookie = {
  name: string;
  title: string;
  value: boolean;
  description: string;
};

export enum TEXTS {
  YES = "Oui",
  NO = "Non",
  REQUIRED = "Ce champ est obligatoire",
  REQUIRED_SELECTION = "Sélection obligatoire",
  EMPTY = "",
  OPTIONAL = "(Optionnel)",
  MANY = "(plusieurs réponses possibles)",
  AMOUNT = "amount",
  DONT_KNOW = "Je ne sais pas",
}

export interface Customer {
  firstName?: string;
  lastName?: string;
  phone?: string;
  email?: string;
  companyName?: string;
  businessSector?: string;
  postalCode?: string;
  job?: string;
}

export class Quote {
  consents: Consent[];
  customer: Customer;
  ref: number;
  surveyB: Survey[];
  surveyW: Survey[];
  __v: number;
  _id: string;
  creationDate?: Date;
  emailFollowUp?: Date;
  quoteResult?: string;

  constructor() {
    this._id = "";
    this.customer = {
      firstName: "",
      lastName: "",
      email: "",
      companyName: "",
      postalCode: "",
      job: "",
    };
    this.ref = 1;
    this.surveyB = [];
    this.surveyW = [];
    this.__v = 1;
    this.consents = [];
  }
}

export interface Survey {
  data: SurveyData[];
  type: string;
}

export interface SurveyData {
  value: boolean | string;
  name: string;
  date: Date | string;
  answer: string;
}

export interface Consent {
  label: string;
  value: string | boolean;
  date: Date | string;
}

export enum EndpointType {
  GET = "get",
  PUT = "put",
  POST = "post",
}

export interface ConditionAffect {
  type: ConditionAffectTypes;
  number?: number;
  value?: any;
}

export enum ConditionAffectTypes {
  HIDE_QUESTION = "hide_question",
  STAGE_NOT_AVAILABLE = "stage_not_available",
  SET_ANSWER = "set_answer",
  VALUE = "value",
  VALUE_DIFFERENT_THAN = "value_different_than",
}

export interface StageObject {
  answered: boolean;
  label: string;
  omitted?: boolean;
  lastAnswerNumber?: number;
  firstAnswerNumber?: number;
  number: number;
  changed?: boolean;
  whichList?: number;
  isSummary?: boolean;
}

export const getBackendEnergyType = (type: string) => {
  let result = "";
  switch (type) {
    case "AirConditioning":
      result = EnergyTypeSecond.AIR_CONDITIONER;
      break;
    case "Heating":
      result = EnergyTypeSecond.HEATING;
      break;
    case "VMC":
      result = EnergyTypeSecond.VMC;
      break;
    case "DomesticHotWater":
      result = EnergyTypeSecond.DOMESTIC_HOT_WATER;
      break;
    case "Lighting":
      result = EnergyTypeSecond.LIGHTNING;
      break;
    case "HeatingSystem":
      result = EnergyType.HEATING;
      break;
    case "HotWaterSystem":
      result = EnergyType.HOT_WATER;
      break;
    case "LightingSystem":
      result = EnergyType.LIGHTNING;
      break;
    case "EnergyConsumptionMonitoringAndCommunicationSystem":
      result = EnergyType.ENERGY_CONSUMPTION;
      break;
    default:
      result = "";
      break;
  }
  return result;
};
