import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MetricsService } from './metrics.service';
import { UserService } from './user.service';
import { ReportingReceiversService } from './reporting-receivers.service';
import { EcotroksService } from './ecotroks.service';
import { NotificationsService } from './notifications.service';
import { UnavailableServiceService } from './unavailable-service.service';
import { UploadService } from './upload.service';
import { AdminConfigService } from './admin-config.service';
import { MandatesService } from './mandates.service';
import { MarketingDocumentsService } from './marketing-documents.service';
import { TopInfoService } from './top-info.service';
import { NewsFeedService } from './news-feed.service';
import { FAQService } from './faq.service';
import { ComparaisonSentenceService } from './comparaison-sentence.service';
import { TracesService } from './traces.service';
import { SearchUserService } from './search-user.service';
import { GenerateInvitationService } from './generate-invitation.service';
import { AuthService } from './auth.service';
import { HousingBlocksService } from './housing-blocks.service';
import { CustomerSubscriptionService } from './customer-subscriptions.service';
import { ErlService } from './erl.service';
import { QuotesService } from './quotes.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  exports: [],
  providers: [
    MetricsService,
    UserService,
    ReportingReceiversService,
    EcotroksService,
    NotificationsService,
    UnavailableServiceService,
    UploadService,
    AdminConfigService,
    MandatesService,
    MarketingDocumentsService,
    TopInfoService,
    NewsFeedService,
    FAQService,
    ComparaisonSentenceService,
    TracesService,
    SearchUserService,
    GenerateInvitationService,
    AuthService,
    HousingBlocksService,
    CustomerSubscriptionService,
    ErlService,
    QuotesService
  ],
})
export class BackendModule {}
