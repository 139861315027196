import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SupervisionWSOutput } from '../../@types/SupervisionWS';
import { NbDialogService } from '@nebular/theme';
import { SimulationErrorMessageComponent } from '../components/simulation-error-message/simulation-error-message.component';
import { UsersJson } from '../../@types/UserJson';
import { UserService } from '../../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class SimulationModeService {

  permissions: { [key: string]: any } = undefined;

  eventEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  simulatedUserID = undefined;
  supervisionLink = undefined;

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private dialogService: NbDialogService) {}

  async enable(userID: string, open: boolean = false): Promise<string> {
    if (!this.permissions) {
      await this.updatePermissions();
    }

    if (this.simulatedUserID) {
      this.alreadyActivePopupMessage();
      return;
    }

    try {
      const users = await this.userService.getUser(userID).toPromise();

      if (!users || !users.users.length) {
        throw new Error('Can\'t find simulated user');
      }

      const user = users.users[0];

      const appGroups = await this.userService.getGroups().toPromise();
      const userGroups = appGroups
        .filter(group => user.g.includes(group._id))
        .map(group => group.name);

      const permittedGroupsToSupervise = this.permissions.groups || [];
      const authorizedUserGroups = userGroups.filter(group => permittedGroupsToSupervise.includes(group));

      if (!authorizedUserGroups.length) {
        this.notAvailableForUserPopupMessage();
        return;
      }

      const token = await this.getTokenForUser(userID);
      this.supervisionLink = `/supervise_user?token=${token}`;
      this.simulatedUserID = userID;

      this.eventEmitter.emit(true);

      if (open) {
        window.open(this.supervisionLink || '/', '_blank');
      }

      return this.supervisionLink;
    } catch (err) {
      console.error('Failed to start simulation mode for user', userID, err);
    }
  }

  alreadyActivePopupMessage() {
    this.dialogService.open(
      SimulationErrorMessageComponent,
      {
        context: {
          title: 'Une supervision utilisateur est déjà en cours',
          message: 'Quittez la supervision active avant d\'en activer une nouvelle.',
        },
      });
  }

  notAvailableForUserPopupMessage() {
    this.dialogService.open(
      SimulationErrorMessageComponent,
      {
        context: {
          title: 'Supervision impossible pour cet utilisateur',
          message: 'Le statut de l\'utilisateur selectionné ne permet pas une supervision.',
        },
      });
  }

  async disable() {
    this.simulatedUserID = undefined;
    this.supervisionLink = undefined;
    this.eventEmitter.emit(false);

    try {
      await this.http.get('/supervise_user_quit', { withCredentials: true, responseType: 'text' }).toPromise();
      this.eventEmitter.emit(false);
    } catch(err) {
      console.error(err);
    }
  }

  async getTokenForUser(userID) {
    const response = await this.http.post<SupervisionWSOutput>('/api/auth/generate-supervision-token', {superviseUID: userID}).toPromise();
    return response.token;
  }

  /**
   * Check if a simulation is already active, retreive token if so
   */
  async check() {
    try {
      const resp = await this.http.get<UsersJson>('/supervise_user_check', { withCredentials: true }).toPromise();
      if (resp && resp.users && resp.users.length) {
        const userID = resp.users[0]._id;

        const token = await this.getTokenForUser(userID);

        this.supervisionLink = `/supervise_user?token=${token}`;
        this.simulatedUserID = userID;

        this.eventEmitter.emit(true);
      }
    } catch(err) {
      this.eventEmitter.emit(false);
    }
  }

  getLink() {
    return this.supervisionLink;
  }

  getSimulatedUserID() {
    return this.simulatedUserID;
  }

  isEnabled() {
    return this.simulatedUserID !== undefined;
  }

  updateStatus() {
    this.eventEmitter.emit(this.isEnabled());
  }

  async updatePermissions() {
    this.permissions = await this.userService.getUserGroupFeatureOptions('current', 'crm.supervision').toPromise();
  }

  subscribe(fn) {
    return this.eventEmitter.subscribe(fn);
  }
}
