<nb-card accent="warning">
    <nb-card-header>
        {{ title }}
    </nb-card-header>

    <nb-card-body>
        <div>
            {{ message }}
        </div>
    </nb-card-body>

    <nb-card-footer>
        <button status="primary" nbButton (click)="close()">
            OK
        </button>
    </nb-card-footer>
</nb-card>
