import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { UnavailableServiceService } from './unavailable-service.service';
import { SigninData } from '../@types/SigninData';
import { UsersJson } from '../@types/UserJson';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    powervasAPI = '/api';

    constructor(
        private httpClient: HttpClient,
        private unavailableServiceService: UnavailableServiceService,
        private userService: UserService) {}

    isLoggedIn(): Observable<boolean> {
        return this.userService.getUser('current').pipe(
            map((users) => users.users.length > 0),
            catchError(() => of(false))
        );
    }

    signin(username: string, password: string, remember: boolean): Observable<SigninData> {
        return this.unavailableServiceService.handle(
            this.httpClient.post<SigninData>(
                `${this.powervasAPI}/signin`,
                {username, password, remember}
            ),
            this.powervasAPI,
        );
    }

    signout() {
        return this.httpClient.get(
            `${this.powervasAPI}/signout`,
            {
                responseType: 'arraybuffer'
            }
        );
    }
}
