import { NgControl } from '@angular/forms';
import { Input, Directive } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[disableControl]'
})
export class DisableControlDirective {
  constructor( private ngControl: NgControl ) {
  }

  @Input() set disableControl( condition: boolean ) {
    if (this.ngControl.control) {
      const action = condition ? 'disable' : 'enable';
      this.ngControl.control[action]();
    }
  }
}
