<span class="period-selector-container">
    <span class="period-title">{{ 'datepicker.from' | translate }} :</span>
    <input nbInput [nbDatepicker]="fromDatePickerValue" [(ngModel)]="from">
    <nb-datepicker #fromDatePickerValue></nb-datepicker>

    <span class="period-title">{{ 'datepicker.to' | translate }} :</span>
    <input nbInput [nbDatepicker]="toDatePickerValue" [(ngModel)]="to">
    <nb-datepicker #toDatePickerValue></nb-datepicker>

    <button nbButton
        status="control"
        size="small"
        (click)="substractMonth()"
        nbTooltip="Période précédente">
        <nb-icon icon="arrow-left"></nb-icon>
    </button>

    <button nbButton
        status="control"
        size="small"
        (click)="addMonth()"
        nbTooltip="Période suivante">
        <nb-icon icon="arrow-right"></nb-icon>
    </button>
</span>