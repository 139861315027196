<div class="user-profile" *ngIf="!requestOpen">
  <div class="image-container">
    <img src="./assets/images/pp.png" alt="">
  </div>

  <div class="user-fields" *ngIf="user">
    <span class="group-badge" *ngFor="let group of userGroups" [translate]="'users.card.types.' + group.name"></span>
    <br>
    <span class="user-field"><span class="label" translate=users.card.details.fields.ref></span> #{{ user.ref || '-' }}</span>
    <br>
    <span class="user-field"><span class="label" translate=users.card.details.fields.lastname></span> {{ user.first_name || '-' }}</span>
    <span class="user-field"><span class="label" translate=users.card.details.fields.firstname></span> {{ user.last_name || '-' }}</span>
    <br>
    <span class="user-field"><span class="label" translate=users.card.details.fields.email></span> {{ user.email || '-' }}</span>
    <br>
    <span class="user-field"><span class="label" translate=users.card.details.fields.phone></span> {{ user.phone || '-' }}</span>
  </div>

  <div class="user-fields" *ngIf="user">
    <span class="user-field"><span class="label" translate=users.card.details.fields.invited></span> {{ user.invitedAtString || 'common.no' | translate }}</span>
    <br>
    <span class="user-field"><span class="label" translate=users.card.details.fields.initialized></span> {{ user.initDateString || 'common.no' | translate }}</span>
  </div>
</div>

<div *ngIf="userSites && userSites.length">
  <div class="contracts-item" *ngIf="!requestOpen">
    <nb-list *ngIf="requests && requests.length > 0">
      <nb-list-item *ngFor="let request of requests; let i = index" class="item" [ngClass]="'status-' + request.status" (click)="openRequest(request)">
        <div class="item-body">
          <span>
            <img src="./assets/images/contact_support.svg">
          </span>
          <span class="field-container">
            <span class="field-column">
              <span class="user-field"><span class="label" translate=users.card.requests.fields.type></span> {{ request.title || '-' }}</span>
              <br>
              <span class="user-field"><span class="label" translate=users.card.requests.fields.dateCreation></span> {{ (request.creationDate ? request.creationDate.toLocaleString("fr-FR") : '-') }}</span>
            </span>
            <span class="field-column">
              <span class="user-field"><span class="label" translate=users.card.requests.fields.status></span> {{ request.statusLabel || '-' }} </span>
              <br>
              <span class="user-field"><span class="label" translate=users.card.requests.fields.ref></span> {{ request.requestId || '-' }}</span>
              <br>
              <span class="user-field"><span class="label" translate=users.card.requests.fields.contract></span> {{ request.contractId || '-' }}</span>
            </span>
          </span>
        </div>
      </nb-list-item>
    </nb-list>
  </div>
  <div class="user-profile request-details" *ngIf="requestOpen" [ngClass]="'status-' + requestOpened.status">
    <span class="request-header">
      <span class="request-title">{{ requestOpened.title || '-' }}</span>
      <span class="close-button">
        <button nbButton ghost nbTooltip="Fermer" (click)="closeRequest()">
          <nb-icon icon="close-outline" pack="eva"></nb-icon>
        </button>
      </span>
    </span>
    <span class="field-container">
      <span class="field-column">
        <span class="user-field"><span class="label" translate=users.card.requests.fields.type></span> {{ requestOpened.title || '-' }}</span>
        <br>
        <span class="user-field"><span class="label" translate=users.card.requests.fields.dateCreation></span> {{ (requestOpened.creationDate ? requestOpened.creationDate.toLocaleString("fr-FR") : '-') }}</span>
        <br>
        <span class="user-field"><span class="label" translate=users.card.requests.fields.dateClose></span> {{ (requestOpened.closingDate ? requestOpened.closingDate.toLocaleString("fr-FR") : '-') }}</span>
      </span>
      <span class="field-column">
        <span class="user-field"><span class="label" translate=users.card.requests.fields.status></span> {{ requestOpened.statusLabel || '-' }} </span>
        <br>
        <span class="user-field"><span class="label" translate=users.card.requests.fields.ref></span> {{ requestOpened.requestId || '-' }}</span>
        <br>
        <span class="user-field"><span class="label" translate=users.card.requests.fields.contract></span> {{ requestOpened.contractId || '-' }}</span>
      </span>
    </span>
  </div>
</div>
<span *ngIf="!requestOpen && requests && requests.length < 1" class="no-requests" translate=users.card.requests.noone.open></span>
<span *ngIf="!requestOpen && requestError" class="no-requests"  translate=users.card.requests.error></span>
