import { Component, OnInit, Input } from '@angular/core';
import { UserJson } from '../../../../@types/UserJson';
import { VariablesJson } from '../../../../@types/VariableJson';
import { SiteJson } from '../../../../@types/SiteJson';

@Component({
  selector: 'gp-user-details-rgpd',
  templateUrl: './user-details-rgpd.component.html',
  styleUrls: ['./user-details-rgpd.component.scss'],
})
export class UserDetailsRgpdComponent implements OnInit {
  @Input() userSites: Array<SiteJson>;
  @Input() user: UserJson;

  constructor() {}

  ngOnInit() {}
}
