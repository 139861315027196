import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'gp-analytic-card-back',
  templateUrl: './analytic-card-back.component.html',
  styleUrls: ['./analytic-card-back.component.scss']
})
export class AnalyticCardBackComponent implements OnInit {

  @Input() columns: Array<{ label: string; fieldName: string }> = [];
  @Input() data: Array<any> = [];
  @Input() showIndex = true;

  constructor() { }

  ngOnInit(): void {
  }

}
