import { Injectable } from '@angular/core';

import * as moment from 'moment';
import { UserService } from './user.service';
import { SitesJson } from '../@types/SiteJson';

@Injectable({
  providedIn: 'root'
})
export class GenerateInvitationService {
  constructor(private userService: UserService) { }

  async generate(user: any, strategy: string) {
    let url;

    switch (strategy) {
      case 'myNexio':
        url = await this.mynexioStrategy(user);
        break;
      case 'selia':
        url = await this.seliaStrategy(user);
        break;
      default:
        console.warn('No invitation generation strategy configured');
        break;
    }

    window.open(url.href, '_blank');
  }

  async seliaStrategy(user: any): Promise<URL> {
    const WS_URL = '/pdf_gen/pdf_admin';

    let address = '';
    const fullAdr = user.full_address.replace(/\n/g, ' ');
    if(user.address === fullAdr){
        const splitAddress = user.full_address.split('\n');;
        address = splitAddress ?
            {
                streetNumber: splitAddress[0] || '', // 1
                streetName: '',
                postalCode: splitAddress[1] || '', // 2
                locality: '',
            } : user.address;
    } else {
        const splitAddress = user.full_address.split('\n');
        const n = splitAddress.length;
        address = splitAddress ?
            {
                streetNumber: splitAddress[n-3] || '', // 1
                compl: splitAddress[n-2] || '', // 3
                streetName: '',
                postalCode: splitAddress[n-1] || '', // 2
                locality: '',
            } : user.full_address;
    }

    const url = new URL(`${window.origin}${WS_URL}`);
    url.searchParams.append('nameParticle', user.name_particle);
    url.searchParams.append('name', user.name);
    url.searchParams.append('login', user.login);
    url.searchParams.append('password', user.cryptedTmpPwd);
    url.searchParams.append('date', moment().format('dddd DD MMMM YYYY'));
    url.searchParams.append('address', JSON.stringify(address));

    return url;
  }

  async mynexioStrategy(user: any): Promise<URL> {
    const WS_URL = '/pdf_gen/pdf';

    const userFromWS = (await this.userService.getUser(user._id).toPromise()).users[0];

    const sites: Array<SitesJson> = await Promise.all(
      user.sites_list.map(s => this.userService.getSite(s._id).toPromise())
    );

    let address = user.full_address;
    if (address){
      address = address.replace(/\n/g, '<br>');
    } else{
      address = user.address;

      if(address.indexOf('98000') > 0){
        const idx = address.indexOf('98000');
        address = address.substring(0, idx-1) + '<br>' + address.substring(idx);
      }
    }

    const uniqueEdl = [];
    sites.forEach((element) => {
      //Select only active sites
      const [site] = element.sites;
      const activeContract = site && site.contractsTimeline.find(e => e.status === 1);
      if (activeContract && !uniqueEdl.includes(site.edl.ref)) {
        // Remove Doublon from ref edl
        uniqueEdl.push(site.edl.ref);
      }
    });

    console.log(uniqueEdl.toString());

    const url = new URL(`${window.origin}${WS_URL}`);
    url.searchParams.append('model', 'letter');
    url.searchParams.append('name', `${userFromWS.name_particle} ${userFromWS.first_name} ${userFromWS.last_name}`);
    url.searchParams.append('username', user.login);
    url.searchParams.append('date', moment().format('dddd DD MMMM YYYY'));
    url.searchParams.append('address', address);
    url.searchParams.append('password', user.cryptedTmpPwd);
    url.searchParams.append('edl', uniqueEdl.toString());

    return url;
  }
}
