/**
 * Copyright (C) 2023 GridPocket SAS - All Rights Reserved
 * Unauthorized usage, modification or copying of this file, via any medium are strictly prohibited
 * Proprietary and confidential
 */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

export interface IAppConfig {
    recaptchaKey: string | null;
    mapkit_token: string | null;
    url_ael: string | null;
    marketingDocLabels: boolean | null;
}

@Injectable({
    providedIn: 'root'
})
export class AppConfig {
    private config: IAppConfig = null;

    constructor(private http: HttpClient) {}

    /**
     * Use to get the data found in the second file (config file)
     */
    public get(key: any) {
        return this.config[key];
    }

    /**
     * This method:
     *   a) Loads "environment.json" to get the current working environment (e.g.: 'production', 'development')
     *   b) Loads "config.[environment].json" to get all environment's variables (e.g.: 'config.development.json')
     */
    public async load() {
        const assetConfigFile = await this.http.get<IAppConfig>('assets/config/configuration.json').toPromise();
        this.config = assetConfigFile;
    }
}
