import { registerLocaleData } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { NbIconLibraries } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';

import localeFr from '@angular/common/locales/fr';
import localeGb from '@angular/common/locales/en-GB';
import localeDe from '@angular/common/locales/de';
import { AppConfig } from './shared/services/app-config.service';
import { CDNService } from './shared/services/cdn.service';
@Component({
  selector: 'gp-app',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {
  defaultLanguage = 'fr';

  constructor(
    private iconsLib: NbIconLibraries,
    @Inject(LOCALE_ID) private locale: string,
    private cookieService: CookieService,
    private translateService: TranslateService,
    private appConfig: AppConfig,
    private cdnService: CDNService,
  ) {
      this.iconsLib.registerFontPack('font-awesome', { iconClassPrefix: 'fa' });

      registerLocaleData(localeFr, 'fr');
      registerLocaleData(localeGb, 'gb');
      registerLocaleData(localeDe, 'de');

      let language = this.defaultLanguage;

      if (this.cookieService.check('lang')) {
        language = this.cookieService.get('lang');
      }

      this.translateService.onLangChange.subscribe(() => {
        this.locale = this.translateService.currentLang;
      });

      translateService.setDefaultLang(this.defaultLanguage);
      translateService.use(language);


      ////////////////////////////////////////////////////////////////
      ///////         SETING UP EXTERNAL SCRIPTS             /////////
      ////////////////////////////////////////////////////////////////
      const recaptchaKey = this.appConfig.get('recaptchaKey');

      if (recaptchaKey && recaptchaKey.length) {
        console.log('Registering CDN recaptcha');
        this.cdnService.addScript({ name: 'recaptcha', src: `https://www.google.com/recaptcha/api.js?render=${recaptchaKey}` });
        this.cdnService.load('recaptcha');
      }
      const mapkitKey = this.appConfig.get('mapkit_token');
      if (mapkitKey && mapkitKey.length) {
        console.log('Registering Mapkit');
        this.cdnService.addScript({ name: 'mapkit', src: 'https://cdn.apple-mapkit.com/mk/5.x.x/mapkit.js' });
      }
  }

  ngOnInit(): void {
  }
}
