/**
 * Copyright (C) 2022 GridPocket SAS - All Rights Reserved
 * Unauthorized usage, modification or copying of this file, via any medium are strictly prohibited
 * Proprietary and confidential
 */


import { Injectable } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { UsersDetailsModalComponent } from '../components/user-details-modal/user-details-modal.component';

@Injectable({
  providedIn: 'root'
})
export class UserDetailsModalService {
  constructor(private dialogService: NbDialogService) {}

  open(userID) {
    this.dialogService.open(UsersDetailsModalComponent, { context: { userID } });
  }
}
