<style>
  .firstDivision {
    display: table;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  .firstDivision .verticalCenter {
    text-align: center;
    display: table-cell;
    vertical-align: middle;
  }
</style>
<div class="firstDivision">
  <div class="verticalCenter">
  </div>
</div>

