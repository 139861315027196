import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FeatureComparaisonSentenceJson, FeatureComparaisonSentencesJson } from '../@types/FeatureComparaisonSentenceJson';
import { UnavailableServiceService } from './unavailable-service.service';

@Injectable({
  providedIn: 'root'
})
export class ComparaisonSentenceService {
  powervasAPI = '/api/features/feature_comparaison_sentence';

  constructor(private httpClient: HttpClient, private unavailableServiceService: UnavailableServiceService) { }

  get() {
    return this.unavailableServiceService.handle(
      this.httpClient.get<FeatureComparaisonSentencesJson>(`${this.powervasAPI}`),
      this.powervasAPI,
    );
  }

  remove(id) {
    return this.unavailableServiceService.handle(
      this.httpClient.delete(`${this.powervasAPI}/${id}`),
      this.powervasAPI,
    );
  }

  create(obj) {
    return this.unavailableServiceService.handle(
      this.httpClient.post(`${this.powervasAPI}`, obj),
      this.powervasAPI,
    );
  }

  update(item: FeatureComparaisonSentenceJson) {
    return this.unavailableServiceService.handle(
      this.httpClient.put<any>(`${this.powervasAPI}/${item._id}`, item),
      this.powervasAPI,
    );
  }
}
