import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AscDesc, DirectionNames, Order, Traces } from '../@types/TraceJson';
import { UnavailableServiceService } from './unavailable-service.service';

@Injectable({
  providedIn: 'root'
})
export class TracesService {
  powervasAPI = '/api/traces';

  constructor(private httpClient: HttpClient, private unavailableServiceService: UnavailableServiceService) { }

  get() {
    return this.unavailableServiceService.handle(
      this.httpClient.get<Traces>(`${this.powervasAPI}`),
      this.powervasAPI,
    );
  }

  /**
   * Get traces with options
   *
   * @param length - how many traces needed
   * @param start - from where to start (pagination)
   * @param search - search inpout
   * @param order - order by which column and what direction
   * @param columns - list of columns to get
   * @returns
   */
  getTraces(
    length: number = 15,
    start: number = 0,
    search: string,
    order: Order = {},
    columns: string[] = []
  ) {
    const uri = `${
      this.powervasAPI
    }?${this.createColumns(columns)}length=${length}&start=${start}${
      this.getOrder(columns, order)}&search[value]=${search?.length ? search : ''}&search[regex]=false`;
    return this.unavailableServiceService.handle(
      this.httpClient.get<Traces>(uri),
      this.powervasAPI,
    );
  }

  /**
   * Generate columns in the URI
   *
   * @param columns - list of columns to generate
   * @returns
   */
  createColumns(columns: string[]) {
    let stringToReturn = '';
    columns.forEach((column, index) => {
      stringToReturn += `columns[${index}][data]=${column}&columns[${index}][name]=${column}&columns[${index}][searchable]=true&columns[${
        index}][orderable]=true&`;
    });
    return stringToReturn;
  }

  /**
   * Get column number and direction for getting the data in the order
   *
   * @param columns - list of columns that are displayed
   * @param order - filed name and direction number (-1 = descending, 1 = ascending)
   * @returns
   */
  getOrder(columns: string[], order) {
    const orderNumber = Object.keys(order) ? columns.indexOf(Object.keys(order)[0]) : '2';
    const orderDirectory = Object.values(order) ? (Object.values(order)[0] === AscDesc.ASC ?
    DirectionNames.ASCENDING : DirectionNames.DESCENDING) : DirectionNames.DESCENDING;
    return `&order[0][column]=${orderNumber}&order[0][dir]=${orderDirectory}`;
  }
}
