/**
 * Copyright (C) 2023 GridPocket SAS - All Rights Reserved
 * Unauthorized usage, modification or copying of this file, via any medium are strictly prohibited
 * Proprietary and confidential
 */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomerSubscriptionsTable } from '../@types/CustomerSubscription';
import { UnavailableServiceService } from './unavailable-service.service';


@Injectable({
    providedIn: 'root',
})
export class CustomerSubscriptionService {
    constructor(private httpClient: HttpClient, private unavailableServiceService: UnavailableServiceService) {}

    getSubscriptions(search: string, start: number, length: number): Observable<CustomerSubscriptionsTable> {
        return this.unavailableServiceService.handle(
            this.httpClient.get<CustomerSubscriptionsTable>(`/api/metrics/customerSubscriptions?start=${start}&length=${length}${search ? `&search=${search}` : '' }`),
            '/api/metrics/customerSubscriptions',
        );
    }
}
