import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';

@Component({
    selector: 'gp-signin',
    templateUrl: './signin.component.html',
    styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {

    loginForm: FormGroup;
    submitted = false;
    showPassword = true;
    loading = true;
    wrongCredentials = false;

    constructor(
        private formBuilder: FormBuilder,
        private authService: AuthService,
        private router: Router
    ) {
    }

    // convenience getter for easy access to form fields
    get getDataForm() {
        return this.loginForm.controls;
    }

    async ngOnInit() {
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });
        const authenticated = await this.authService.isLoggedIn().toPromise();
        if (authenticated) {
            await this.router.navigate(['/landing-page']);
            return;
        }
        this.loading = false;
    }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }
        this.loading = true;
        this.wrongCredentials = false;
        this.simplelogIn();
    }

    simplelogIn() {
        this.authService.signin(
            this.loginForm.value.username,
            this.loginForm.value.password,
            false,
        ).subscribe(async () => {
            this.loading = false;
            await this.router.navigate(['landing-page']);
        }, (err) => {
            console.log('ERROR', err);
            this.loading = false;
            this.wrongCredentials = true;
        });
    }

    toggleShowPassword() {
        this.showPassword = !this.showPassword;
    }
}





