import { Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

declare let process: any;
const env = process.env.NODE_ENV;

@Component({
  selector: 'gp-signin-ext',
  templateUrl: './signin-ext.component.html',
})
export class SigninExtComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}

  async ngOnInit() {
    const authenticated = await this.authService.isLoggedIn().toPromise();
    if(authenticated) {
      this.router.navigateByUrl('landing-page');
    } else {
      window.location.href = '/ext-auth/2/login';
    }
  }
}
