import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UnavailableServiceService } from './unavailable-service.service';

@Injectable({
  providedIn: 'root'
})
export class HousingBlocksService {
  msMetrics = '/api/metrics';

  constructor(private httpClient: HttpClient, private unavailableServiceService: UnavailableServiceService) { }

  getHousingBlocks() {
    return this.unavailableServiceService.handle(
      this.httpClient.get<Array<string>>(`${this.msMetrics}/housing-blocks`),
      this.msMetrics,
    );
  }
}
