/* eslint-disable no-underscore-dangle */
/*
 * Copyright (C) 2022 GridPocket SAS - All Rights Reserved
 * Unauthorized usage, modification or copying of this file, via any medium are strictly prohibited
 * Proprietary and confidential
 */
/**
 * Copyright (C) 2021 GridPocket SAS - All Rights Reserved
 * Unauthorized usage, modification or copying of this file, via any medium are strictly prohibited
 * Proprietary and confidential
 */

export class QuotesJson {
    area?: number;
    energyPrice?: number;
    heatingOrCoolingProportion?: number;
    consents: Consent[];
    customer: Customer;
    ref: number;
    surveyB: Survey[];
    surveyW: Survey[];
    __v: number;
    _id: string;
    createdAt?: Date;
    updatedAt?: Date;
    emailFollowUp?: Date;
    surveyPercent?: {
        surveyW: number;
        surveyB: number;
    };
    status?: number;
    comments: Consent[];
    quoteBudget?: QuoteBudget;
    quoteResult?: QuoteResult;
    commonCoolingHeatingSystem?: string[];
    quote2_need_to_be_refined?: boolean;


    constructor() {
        this._id = null;
        this.customer = {
            firstName: '',
            lastName: '',
            email: ''
        };
        this.ref = 1;
        this.surveyB = [];
        this.surveyW = [];
        this.__v = 1;
        this.consents = [];
        this.surveyPercent = {
            surveyW: 0,
            surveyB: 0,
        };
    }
}

export interface PriceJson {
    _id: string;
    name: string;
    unitPrice: number;
}

export interface QuoteResult {
    AirConditioning: number;
    EnergyConsumptionMonitoringAndCommunicationSystem: number;
    HeatingSystem: number;
    HotWaterSystem: number;
    LightingSystem: number;
    total: number;
}

export interface QuoteBudget {
    packDetails: PackDetail[];
    total: number;
}

export interface PackDetail {
    componentList: any[];
    componentPrices: any[];
    packDefinitions: any;
    packName: string;
    quantity: number;
    total: number;
}
export interface Survey {
    data: SurveyData[];
    type: string;
}

export interface SurveyData {
    value: boolean | string;
    name: string;
    date: Date;
}

export interface Consent {
    label: string;
    value: string;
    date: Date;
}

export interface Customer {
    firstName: string;
    lastName: string;
    phone?: string;
    email: string;
    companyName?: string;
    businessSector?: string;
    postalCode?: string;
    job?: string;
}

export enum QuoteStatus {
    PENDING = 0,
    TO_CONTACT = 1,
    TERMINATED = 2,
    VALIDATED = 3,
    REFUSED = 4
}

export const QuoteStatusName = {
    0: 'EN COURS',
    1: 'À CONTACTER',
    2: 'TERMINÉ',
    3: 'CONTACTÉ',
    4: 'ANNULÉ',
  };

  export const QuoteStatusClass = {
    0: 'pending',
    1: 'to_contact',
    2: 'terminated',
    3: 'validated',
    4: 'refused',
  };

  export const QuoteStatusTooltips = {
    0: `L'utilisateur n'a pas fini le questionnaire.`,
    1: `L'utilisateur a souhaité être contacté.`,
    2: `L'utilisateur ne souhaite pas etre contacté.`,
    3: `L'utilisateur a été contacté.`,
    4: `L'utilisateur n'est finalement pas contactable.`,
  };
