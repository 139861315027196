import { Component, OnInit, OnDestroy, Inject, LOCALE_ID } from '@angular/core';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'gp-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss']
})
export class DateRangePickerComponent implements OnInit {

  from: Date = moment().add(-1, 'month').startOf('day').toDate();
  to: Date = moment().endOf('day').toDate();

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.translateService.onLangChange.subscribe(() => this.onLanguageChange());
    this.onLanguageChange();
  }

  onLanguageChange() {}

  substractMonth() {
    this.from = moment(this.from).add(-1, 'month').toDate();
    this.to = moment(this.to).add(-1, 'month').toDate();
  }

  addMonth() {
    const newTo = moment(this.to).add(1, 'month');
    if (newTo.diff(moment().endOf('day')) <= 0) {
      this.from = moment(this.from).add(1, 'month').toDate();
      this.to = newTo.toDate();
    }
  }
}
