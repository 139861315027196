import { Component, Input, Output, OnInit, ViewChild, ElementRef, EventEmitter } from '@angular/core';
import { FeatureAgencyJson } from '../../../../../@types/FeatureAgencyJson';
import { AppConfig } from '../../../../../shared/services/app-config.service';

declare let mapkit: any;

@Component({
  selector: 'gp-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {

  @Output() editPOI = new EventEmitter<FeatureAgencyJson>();
  @Output() deletePOI = new EventEmitter<FeatureAgencyJson>();
  @ViewChild('map') mapElement: ElementRef;

  @Input()
  poiList: FeatureAgencyJson[];

  annotations: Array<any> = [];
  token: string;

  constructor(
    private appConfig: AppConfig,
  ) { }

  ngOnInit(): void {
    this.token = this.appConfig.get('mapkit_token');

    mapkit.init({
      authorizationCallback: (done) => {
        done(this.token);
      }
    });

    this.initMap();
  }

  update(pois) {
    this.mapElement.nativeElement.innerHTML = '';
    this.annotations = [];
    this.poiList = pois;
    this.initMap();
  }


  initMap(): void {

    const map = new mapkit.Map('map');
    map.showsMapTypeControl = false;
    map.showsPointsOfInterest = false;

    let region = new mapkit.CoordinateRegion(
      new mapkit.Coordinate(-21.2107283, 165.6517014),
      new mapkit.CoordinateSpan(4, 4),
    );

    if(this.poiList && this.poiList.length !== 0) {
      if(this.poiList.length === 1) {
        region = new mapkit.CoordinateRegion(
          new mapkit.Coordinate(
            (this.poiList[0].coordinate.latitude),
            (this.poiList[0].coordinate.longitude)
          ),
          new mapkit.CoordinateSpan(0.04, 0.04),
        );
      }
      this.poiList.forEach(poi => {
        const calloutDelegate = {
          calloutContentForAnnotation: (annotation) => {
            const element = document.createElement('div');
              element.className = 'callout-content';
              const styler = element.appendChild(document.createElement('style'));
                styler.innerHTML = `
                  .btn-container {
                    margin-top: 1em;
                  }
                  .action-button {
                    padding: .6875rem 1.125rem;
                    border-style: solid;
                    border-width: 0.0625rem;
                    text-transform: uppercase;
                    border-radius: 0.25rem;
                    font-size: 0.875rem;
                    line-height: 1rem;
                    transition-duration: 0.15s;
                    transition-property: background-color, border-color, color, box-shadow;
                    transition-timing-function: ease-in;
                    user-select: none;
                    text-align: center;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    white-space: nowrap;
                    vertical-align: middle;
                  }
                  .edit-button {
                    background-color: #36f;
                    border-color: #36f;
                    color: #fff;
                  }
                  .edit-button:hover {
                    background-color: #598cff;
                    border-color: #598cff;
                  }
                  .delete-button, .disabled {
                    background-color: #f44;
                    border-color: #f44;
                    color: #fff;
                    margin-left: .5em;
                  }
                  .delete-button:hover {
                    background-color: #ff5f6d;
                    border-color: #ff5f6d;
                  }
                  .disabled, .enabled {
                    font-size: smaller;
                    border-radius: 10px;
                    padding: 2px 5px;
                  }
                  .enabled {
                    background-color: #4caf50;
                    border-color: #4caf50;
                    color: #fff;
                    margin-left: .5em;
                  }
                `;
              const title = element.appendChild(document.createElement('h6'));
                title.textContent = poi.title;
                if (!poi.online) {
                  const offline = title.appendChild(document.createElement('span'));
                    offline.textContent = 'Désactivé';
                    offline.className = 'disabled';
                } else {
                  const online = title.appendChild(document.createElement('span'));
                    online.textContent = 'Activé';
                    online.className = 'enabled';
                }
              if (poi.description.details) {
                const details = element.appendChild(document.createElement('span'));
                  details.innerHTML = `${poi.description.details}<br />`;
              }
              if (poi.description.address) {
                const address = element.appendChild(document.createElement('span'));
                  address.innerHTML = `${poi.description.address.streetNumber} ${poi.description.address.streetName}
                  ${poi.description.address.zipcode} ${poi.description.address.cityName}`;
                  if (poi.description.address.country) {
                    if (poi.description.address.streetName || poi.description.address.zipcode || poi.description.address.cityName) {
                      address.innerHTML += `, `;
                    }
                    address.innerHTML += `${poi.description.address.country}`;
                  }
                  address.innerHTML += `<br />`;
              }
              const btnContainer = element.appendChild(document.createElement('div'));
                btnContainer.className = 'btn-container';
                const editBtn = btnContainer.appendChild(document.createElement('button'));
                  editBtn.textContent = 'Modifier';
                  editBtn.className = 'action-button edit-button';
                  editBtn.onclick = () => {
                    this.editPOI.emit(poi);
                  };
                const deleteBtn = btnContainer.appendChild(document.createElement('button'));
                  deleteBtn.textContent = 'Supprimer';
                  deleteBtn.className = 'action-button delete-button';
                  deleteBtn.onclick = () => {
                    this.deletePOI.emit(poi);
                  };
            return element;
          }
      };
        const poiOption = {
          title: poi.title,
          subtitle: `${poi.description.details}\n
                    ${poi.description.address.streetNumber} ${poi.description.address.streetName}`,
          url: {1: '/assets/images/map-assets/agency_pinmap.svg'},
          callout: calloutDelegate
        };
        if (poi.type === 'BS') {
          poiOption.url = {1: '/assets/images/map-assets/info_pinmap.svg'};
        } else if (poi.type === 'BR') {
          poiOption.url = {1: '/assets/images/map-assets/purple_elec_pinmap.svg'};
        } else if (poi.type === 'R') {
          poiOption.url = {1: '/assets/images/map-assets/pay_pinmap.svg'};
        } else {
          poiOption.url = {1: '/assets/images/map-assets/agency_pinmap.svg'};
        }
        if (!poi.online) {
          poiOption.url = {1: '/assets/images/map-assets/agency_pinmap_disabled.svg'};
        }
        if (poi.coordinate.latitude && poi.coordinate.longitude) {
          this.annotations.push(
            new mapkit.ImageAnnotation(
              new mapkit.Coordinate(poi.coordinate.latitude, poi.coordinate.longitude),
              poiOption
            )
          );
        }
      });
      map.addAnnotations(this.annotations);
    }
    map.region = region;
  }
}
