import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { UserChartMetricsJson } from '../@types/UserChartMetricsJson';
import { PageChartMetricsJson } from '../@types/PageChartMetricsJson';
import { PageTableMetricsJson } from '../@types/PageTableMetricsJson';
import { DevicesMetricsJson } from '../@types/DevicesMetricsJson';
import { EventMetricsJson } from '../@types/EventMetricsJson';
import { EventSourceMetricsJson } from '../@types/EventSourceMetricsJson';
import { EventsChartMetricsJson } from '../@types/EventsChartMetricsJson';
import { MetricsSummaryJson } from '../@types/MetricsSummaryJson';
import { UsersByGroupMetricsJson } from '../@types/UsersByGroupMetricsJson';
import { UsersDataTable } from '../@types/UsersDataTable';
import { FeedbackJson } from '../@types/FeedbackJson';
import { FeedbacksPerDayJson } from '../@types/FeedbacksPerDayJson';
import { FeedbacksPerRateJson } from '../@types/FeedbacksPerRateJson';
import { UnavailableServiceService } from './unavailable-service.service';
import UserlistCsv from '../@types/UserlistCsvJson';
import CsvIsReadyJson from '../@types/CsvIsReadyJson';
import MovingUsersResponseJson from '../@types/MovingUsersResponseJson';
import { EcotroksChartJson } from '../@types/EcotroksChartJson';
import CampaignReports from '../@types/CampaignReports';

@Injectable({
  providedIn: 'root'
})
export class MetricsService {
  msMetrics = '/api/metrics';
  msAdminUserList = '/admin/data';
  msAdminUserListERL = '/admin/erl/data';

  constructor(private httpClient: HttpClient, private unavailableServiceService: UnavailableServiceService) {}

  getUserVisitChart(from: Date, to: Date) {
    return this.unavailableServiceService.handle(
      this.httpClient.get<Array<UserChartMetricsJson>>(`${this.msMetrics}/userchart?from=${from.toISOString()}&to=${to.toISOString()}`),
      this.msMetrics,
    );
  }

  getPagesVisitChart(from: Date, to: Date) {
    return this.unavailableServiceService.handle(
      this.httpClient.get<Array<PageChartMetricsJson>>(`${this.msMetrics}/pagechart?from=${from.toISOString()}&to=${to.toISOString()}`),
      this.msMetrics,
    );
  }

  getPagesVisitTable(from: Date, to: Date) {
    return this.unavailableServiceService.handle(
      this.httpClient.get<Array<PageTableMetricsJson>>(`${this.msMetrics}/pagetable?from=${from.toISOString()}&to=${to.toISOString()}`),
      this.msMetrics,
    );
  }

  getDevicesMetrics(from: Date, to: Date) {
    return this.unavailableServiceService.handle(
      this.httpClient.get<Array<DevicesMetricsJson>>(`${this.msMetrics}/devices?from=${from.toISOString()}&to=${to.toISOString()}`),
      this.msMetrics,
    );
  }

  getEventsMetrics(from: Date, to: Date) {
    return this.unavailableServiceService.handle(
      this.httpClient.get<Array<EventMetricsJson>>(`${this.msMetrics}/events?from=${from.toISOString()}&to=${to.toISOString()}`),
      this.msMetrics,
    );
  }

  getEventsSourceMetrics(from: Date, to: Date, actionType: string | undefined) {
    const action = actionType ? `&eventAction=${actionType}` : '';

    return this.unavailableServiceService.handle(
      this.httpClient.get<Array<EventSourceMetricsJson>>(
        `${this.msMetrics}/eventsSource?from=${from.toISOString()}&to=${to.toISOString()}${action}`),
      this.msMetrics,
    );
  }

  getEventsChartMetrics(from: Date, to: Date, actionType: string | undefined) {
    const action = actionType ? `&eventAction=${actionType}` : '';
    return this.unavailableServiceService.handle(
      this.httpClient.get<Array<EventsChartMetricsJson>>(
        `${this.msMetrics}/eventsChart?from=${from.toISOString()}&to=${to.toISOString()}${action}`),
      this.msMetrics,
    );
  }

  getMetricsSummary(from: Date, to: Date) {
    return this.unavailableServiceService.handle(
      this.httpClient.get<MetricsSummaryJson>(`${this.msMetrics}/summary?from=${from.toISOString()}&to=${to.toISOString()}`),
      this.msMetrics,
    );
  }

  getUsersMetrics() {
    return this.unavailableServiceService.handle(
      this.httpClient.get<Array<UsersByGroupMetricsJson>>(`${this.msMetrics}/usersByGroupTag`),
      this.msMetrics,
    );
  }

  getERLList(
    length: number = 15,
    start: number = 0,
    search: any = { fields: [], value: '' },
    order: any = {},
    filters: Array<string> = [],
  ) {
    return this.unavailableServiceService.handle(
      this.httpClient.get<UsersDataTable>(
        `${this.msAdminUserListERL}/simple`,
        {
          params: {
            length,
            start,
            order: JSON.stringify(order),
            search: JSON.stringify(search),
            'filters[]': filters,
          }
        },
      ),
      this.msAdminUserList,
    );
  }

  getUsersList(
    length: number = 15,
    start: number = 0,
    search: any = { fields: [], value: '' },
    order: any = {},
    filters: Array<string> = [],
  ) {
    return this.unavailableServiceService.handle(
      this.httpClient.get<UsersDataTable>(
        `${this.msAdminUserList}/simple`,
        {
          params: {
            length,
            start,
            order: JSON.stringify(order),
            search: JSON.stringify(search),
            'filters[]': filters,
          }
        },
      ),
      this.msAdminUserList,
    );
  }

  getUsersListCSV(
    search: any = { fields: [], value: '' },
    order: any = {},
    filters: Array<string> = [],
  ) {

    return this.unavailableServiceService.handle(
      this.httpClient.get<UserlistCsv>(
        `${this.msAdminUserList}/csvSimple`,
        {
          params: {
            order: JSON.stringify(order),
            search: JSON.stringify(search),
            filters,
          }
        }
      ),
      this.msAdminUserList,
    );
  }

  userListCSVIsReady(hash: string) {
    return this.unavailableServiceService.handle(
      this.httpClient.get<CsvIsReadyJson>(`${this.msAdminUserList}/csvIsReady?hash=${hash}`),
      this.msAdminUserList,
    );
  }

  getCSVLink(hash: string) {
    return `${this.msAdminUserList}/downloadCSV?hash=${hash}`;
  }

  getFeedbacks(start: number, length: number) {
    return this.unavailableServiceService.handle(
      this.httpClient.get<{ data: Array<FeedbackJson>; count: number }>(`${this.msMetrics}/feedbacks?start=${start}&length=${length}`),
      this.msMetrics,
    );
  }

  getFeedbacksPerDay(from: Date, to: Date) {
    return this.unavailableServiceService.handle(
      this.httpClient.get<FeedbacksPerDayJson>(
        `${
          this.msMetrics
        }/feedbacksPerDay?from=${from.toISOString()}&to=${to.toISOString()}`
      ),
      this.msMetrics,
    );
  }

  getFeedbacksRateAverage() {
    return this.unavailableServiceService.handle(
      this.httpClient.get<number>(`${this.msMetrics}/feedbacksRateAverage`),
      this.msMetrics,
    );
  }

  getFeedbacksPerRate() {
    return this.unavailableServiceService.handle(
      this.httpClient.get<Array<FeedbacksPerRateJson>>(`${this.msMetrics}/feedbacksPerRate`),
      this.msMetrics,
    );
  }

  getMovingUsersList() {
    return this.unavailableServiceService.handle(
      this.httpClient.get<MovingUsersResponseJson>(`${this.msMetrics}/movingclients`),
      this.msMetrics,
    );
  }

  replyToFeedback(uid: string, message: string) {
    return this.unavailableServiceService.handle(
      this.httpClient.post<FeedbackJson>(`${this.msMetrics}/feedbacks/${uid}/reply`, { text: message } ),
      this.msMetrics,
    );
  }

  getEcotroksTopUsers(from: Date, to: Date) {
    return this.unavailableServiceService.handle(
      this.httpClient.get<Array<any>>(`${this.msMetrics}/ecotroks/topUsers?from=${from.toISOString()}&to=${to.toISOString()}`),
      this.msMetrics,
    );
  }

  getEcotroksTotal(to: Date) {
    return this.unavailableServiceService.handle(
      this.httpClient.get<number>(`${this.msMetrics}/ecotroks/total?to=${to.toISOString()}`),
      this.msMetrics,
    );
  }

  getEcotroksChart(from: Date, to: Date) {
    return this.unavailableServiceService.handle(
      this.httpClient.get<{ graph: Array<EcotroksChartJson> }>(
        `${this.msMetrics}/ecotroks/chart?from=${from.toISOString()}&to=${to.toISOString()}`),
      this.msMetrics,
    );
  }

  getAllEcotroksTransactions() {
    return this.unavailableServiceService.handle(
      this.httpClient.get<Array<any>>(`${this.msMetrics}/ecotroks/transactions`),
      this.msMetrics,
    );
  }

  downloadConnections(from: Date, to: Date) {
    return this.unavailableServiceService.handle(
      this.httpClient.get<{ hash: string }>(
        `${this.msMetrics}/loginExport`,
        {
          params: {
            from: from.toISOString(),
            to: to.toISOString()
          }
        }
      ),
      this.msMetrics,
    );
  }

  connectionsFileIsReady(hash: string) {
    return this.unavailableServiceService.handle(
      this.httpClient.get<{ ready: boolean }>(
        `${this.msMetrics}/loginExportIsReady`,
        { params: { hash } }
      ),
      this.msMetrics,
    );
  }

  downloadConnectionsFile(hash: string) {
    return this.unavailableServiceService.handle(
      this.httpClient.get<{ ready: boolean }>(
        `${this.msMetrics}/loginExportDownload`,
        { params: { hash } }
      ),
      this.msMetrics,
    );
  }

  enumerateOfferLabels() {
    return this.unavailableServiceService.handle(
      this.httpClient.get<Array<string>>(
        `${this.msMetrics}/enumerateOfferLabels`,
      ),
      this.msMetrics,
    );
  }

  enumerateCities() {
    return this.unavailableServiceService.handle(
      this.httpClient.get<Array<string>>(
        `${this.msMetrics}/enumerateCities`,
      ),
      this.msMetrics,
    );
  }

  campaignReports() {
    return this.unavailableServiceService.handle(
      this.httpClient.get<Array<CampaignReports>>(
        `${this.msMetrics}/campaignReports`,
      ),
      this.msMetrics,
    );
  }
}
