import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {
    NbDatepickerModule,
    NbDialogModule,
    NbMenuModule,
    NbSidebarModule,
    NbToastrModule,
    NbWindowModule,
} from '@nebular/theme';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {SigninModule} from './signin/signin.module';
import {LandingPageComponent} from './landing-page/landing-page.component';
import {MapGenericModule} from './pages/ui-widgets/agencies/agencies-map/map-generic.module';
import {AppConfig } from './shared/services/app-config.service';
import {SharedModule} from './shared/shared.module';
import {CaptchaInterceptor} from './shared/interceptors/captcha-manager-interceptor/captcha.interceptor';


export const httpLoaderFactory = (http: HttpClient) => new TranslateHttpLoader(http, 'assets/locales/', '.json');

@NgModule({
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CaptchaInterceptor,
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (config: AppConfig) => () => config.load(),
            deps: [AppConfig],
            multi: true,
        },
    ],
    declarations: [AppComponent, LandingPageComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        SigninModule,
        MapGenericModule,
        SharedModule,
        NbMenuModule.forRoot(),
        NbDialogModule.forRoot(),
        NbWindowModule.forRoot(),
        NbToastrModule.forRoot(),
        NbWindowModule.forRoot(),
        NbSidebarModule.forRoot(),
        NbDatepickerModule.forRoot(),

        TranslateModule.forRoot({
            defaultLanguage: 'fr',
            loader: {
                provide: TranslateLoader,
                useFactory: httpLoaderFactory,
                deps: [HttpClient]
            }
        }),
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor() {
    }
}
