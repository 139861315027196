import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { UserService } from './user.service';
import { map } from 'rxjs/operators';
import { ActivatedRouteSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AdminConfigService {

  currentUserAdminConfig: Set<string>;

  constructor(private backend: UserService) {}

  hasRight(route: ActivatedRouteSnapshot): Observable<boolean> {
    if (!this.currentUserAdminConfig) {
      return this.backend.getUserGroupFeaturesKeys('current').pipe(map((features) => {
        this.currentUserAdminConfig = features;

        return this.hasRightForRoute(route);
      }));
    } else {
      return of(this.hasRightForRoute(route));
    }
  }

  private hasRightForRoute(route: ActivatedRouteSnapshot): boolean {
    return this.currentUserAdminConfig.has(route.routeConfig.path);
  }
}
