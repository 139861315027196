import { Component } from '@angular/core';

@Component({
  selector: 'gp-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
  © WIT 2009 - {{ currentYear }}

  <img src="./assets/images/gp-logo.png" width="100" alt="company logo">
  `,
})
export class FooterComponent {
  currentYear = new Date().getFullYear();
}
