<div class="header-container">
  <a (click)="toggleSidebar()" class="sidebar-toggle">
    <nb-icon icon="menu-2-outline"></nb-icon>
  </a>

  <span class="group-label" *ngIf="userAdminGroup && userAdminGroup.adminConfig">
    {{ userAdminGroup.adminConfig.projectName }} - {{ userAdminGroup.adminConfig.label }}
  </span>
</div>

<div class="right-container">
  <gp-language-picker></gp-language-picker>
  <img class="logo" *ngIf="userAdminGroup && userAdminGroup.adminConfig && userAdminGroup.adminConfig.logo" [src]="userAdminGroup.adminConfig.logo" alt="logo">

  <button nbButton ghost (click)="logout()">
    <nb-icon nbTooltip="Déconnexion" icon="power-outline"></nb-icon>
  </button>
</div>
