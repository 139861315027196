import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  NbDatepickerModule,
  NbCardModule,
  NbButtonModule,
  NbSelectModule,
  NbSpinnerModule,
  NbSpinnerDirective,
  NbTableModule,
  NbIconModule,
  NbListModule,
  NbTooltipModule,
  NbContextMenuModule,
  NbRadioModule,
  NbWindowModule,
  NbTabsetModule,
  NbUserModule,
  NbStepperModule,
  NbAlertModule,
  NbToggleModule,
  NbActionsModule,
  NbLayoutModule,
  NbSidebarModule,
  NbThemeModule,
  NbInputModule,
  NbPopoverModule,
  NbAccordionModule,
  NbBadgeModule,
  NbFormFieldModule,
  NbCheckboxModule,
  NbButtonGroupModule,
  NbToastrModule,
} from '@nebular/theme';
import { ChartModule } from 'angular2-chartjs';
import { BackendModule } from '../services/backend.module';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CookieService } from 'ngx-cookie-service';
import { WidgetTitleComponent } from './components/widget-title/widget-title.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { UnavailableServiceNoticeComponent } from './components/unavailable-service-notice/unavailable-service-notice.component';
import { DisableControlDirective } from './directives/disable.control.directive';
import { SimulationAlertComponent } from './components/simulation-alert/simulation-alert.component';
import { SimulationModeService } from './services/simulation-mode.service';
import { PaginationComponent } from './components/pagination/pagination.component';
import { DateRangePickerComponent } from './components/date-range-picker/date-range-picker.component';
import { AnalyticCardComponent } from './components/analytic-card/analytic-card.component';
import { AnalyticCardBackComponent } from './components/analytic-card/analytic-card-back/analytic-card-back.component';
import { TranslateModule } from '@ngx-translate/core';
import { LanguagePickerComponent } from './components/language-picker/language-picker.component';
import { SimulationErrorMessageComponent } from './components/simulation-error-message/simulation-error-message.component';
import { TruncateStringPipe } from './pipes/truncate-string.pipe';
import { CDNService } from './services/cdn.service';

import { UsersDetailsModalComponent } from './components/user-details-modal/user-details-modal.component';
import { UserDetailsComponent } from './components/user-details-modal/user-details/user-details.component';
import { UserDetailsRgpdComponent } from './components/user-details-modal/user-details-rgpd/user-details-rgpd.component';
import { UserDetailsGrdComponent } from './components/user-details-modal/user-details-grd/user-details-grd.component';
import { UserDetailsEcotroksComponent } from './components/user-details-modal/user-details-ecotroks/user-details-ecotroks.component';
import { UserInvitationLinkModalComponent } from './components/user-details-modal/user-invitation-link-modal/user-invitation-link-modal.component';
import { UserOpenRequestsComponent } from './components/user-details-modal/user-open-requests/user-open-requests.component';
import { UserClosedRequestsComponent } from './components/user-details-modal/user-closed-requests/user-closed-requests.component';
import { UserLinkGenerationComponent } from './components/user-details-modal/user-link-generation/user-link-generation.component';
import { UserDetailsModalService } from './services/user-details.service';
import { AppConfig } from './services/app-config.service';
import { QuoteDetailsModalComponent } from './components/quote-details-modal/quote-details-modal.component';
import { QuoteDetailsModalService } from '../pages/quotes/quote-details.service';

// Standard Angular modules
const ANGULAR_MODULES = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  HttpClientModule
];

// Nebullar components
const NB_MODULES = [
  NbDatepickerModule,
  NbCardModule,
  NbButtonModule,
  NbPopoverModule,
  NbSelectModule,
  NbSpinnerModule,
  NbTableModule,
  NbIconModule,
  NbEvaIconsModule,
  NbListModule,
  NbTooltipModule,
  NbContextMenuModule,
  NbRadioModule,
  NbButtonModule,
  NbBadgeModule,
  NbWindowModule,
  NbTabsetModule,
  NbUserModule,
  NbStepperModule,
  NbAlertModule,
  NbToggleModule,
  NbActionsModule,
  NbSidebarModule,
  NbLayoutModule,
  NbInputModule,
  NbAccordionModule,
  NbFormFieldModule,
  NbCheckboxModule,
  NbButtonGroupModule,
];

const CDK_MODULES = [
  DragDropModule,
];

// Other third party modules
const TP_MODULES = [
  ChartModule,
  FontAwesomeModule,
];

const PROVIDERS = [
  NbSpinnerDirective,
  CookieService,
  SimulationModeService,
  // CDNService,
  // AppConfig,
  UserDetailsModalService,
  QuoteDetailsModalService
];

const DIRECTIVES = [
  DisableControlDirective,
];

@NgModule({
  declarations: [
    WidgetTitleComponent,
    HeaderComponent,
    FooterComponent,
    UnavailableServiceNoticeComponent,
    SimulationAlertComponent,
    PaginationComponent,
    DateRangePickerComponent,
    QuoteDetailsModalComponent,

    UsersDetailsModalComponent,
    UserDetailsComponent,
    UserDetailsRgpdComponent,
    UserDetailsGrdComponent,
    UserDetailsEcotroksComponent,
    UserInvitationLinkModalComponent,
    UserOpenRequestsComponent,
    UserClosedRequestsComponent,
    UserLinkGenerationComponent,

    ...DIRECTIVES,
    SimulationAlertComponent,
    PaginationComponent,
    DateRangePickerComponent,
    AnalyticCardComponent,
    AnalyticCardBackComponent,
    LanguagePickerComponent,
    SimulationErrorMessageComponent,
    TruncateStringPipe,
  ],
  imports: [
    ...ANGULAR_MODULES,
    ...NB_MODULES,
    ...TP_MODULES,
    ...CDK_MODULES,
    TranslateModule,
    NbToastrModule.forRoot(),
  ],
  exports: [
    ...ANGULAR_MODULES,
    ...NB_MODULES,
    ...CDK_MODULES,
    ...TP_MODULES,
    ...DIRECTIVES,
    NbToastrModule,
    BackendModule,
    WidgetTitleComponent,
    HeaderComponent,
    FooterComponent,
    UnavailableServiceNoticeComponent,
    SimulationAlertComponent,
    PaginationComponent,
    DateRangePickerComponent,
    AnalyticCardBackComponent,
    AnalyticCardComponent,
    TranslateModule,
    LanguagePickerComponent,
    TruncateStringPipe
  ],
  providers: [
    ...PROVIDERS,
    ...NbThemeModule.forRoot({ name: 'default' }, []).providers,
  ],
})
export class SharedModule {}
