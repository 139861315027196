import { Component, OnInit, Input, ContentChildren } from '@angular/core';

@Component({
  selector: 'gp-widget-title',
  template: `
<div class="widget-title">{{ text }}</div>`,
  styleUrls: ['./widget-title.component.scss']
})
export class WidgetTitleComponent implements OnInit {
  @Input() text: string;

  constructor() { }

  ngOnInit() {
  }

}
