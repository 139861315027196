<div class="popUp">
  <nb-card> 
    <nb-card-header class="header">
      <span translate=users.list.card.title ></span>
        <button appearance="ghost" class="closeButton" nbButton (click)="ref.close()">
          <nb-icon icon="close-outline"></nb-icon>
        </button>
    </nb-card-header>
    <nb-card-body>{{ link }}</nb-card-body>
  </nb-card>
</div>