/**
 * File: backend.module.ts
 * File Created: Friday, 13th December 2019 9:25:13 pm
 * Author: Sacha Cochin (sacha.cochin@gridpocket.com)
 * -----
 * Last Modified: Fri Mar 31 2023
 * Modified By: Sacha Cochin
 * -----
 * GridPocket SAS Copyright (C) 2019 All Rights Reserved
 * This source is property of GridPocket SAS.
 * Please email contact@gridpocket.com for more information.
 */


import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EcotroksShopArticleJson } from '../@types/EcotroksShopArticleJson';
import { EcotroksShopOrderJson, EcotroksShopOrderTable } from '../@types/EcotroksShopOrderJson';
import { EcotroksAccountJson } from '../@types/EcotroksAccountJson';
import { EcotroksGainsJson } from '../@types/EcotroksGainsJson';
import { Observable } from 'rxjs';
import { UnavailableServiceService } from './unavailable-service.service';
import { EcotroksOrdersChart } from '../@types/EcotroksOrdersChart';
import { EcotroksTransaction } from '../@types/EcotroksTransaction';
import { EcotroksGainType, EcotroksGainTypes } from '../@types/EcotroksGainType';

@Injectable({
  providedIn: 'root',
})
export class EcotroksService {
  msEcotroksAccounts = '/api/ecotroks/accounts';
  msEcotroksShop = '/ecotroks/shop';
  msMetrics = '/api/metrics';
  powervasCoreNode = '/api';

  constructor(private httpClient: HttpClient, private unavailableServiceService: UnavailableServiceService) {}

  /**
   * Get registered EcoTrocks articles
   */
  getArticles(): Observable<Array<EcotroksShopArticleJson>> {
    return this.unavailableServiceService.handle(
      this.httpClient.get<Array<EcotroksShopArticleJson>>(`${this.msEcotroksShop}/articles?raw=true`),
      this.msEcotroksShop,
    );
  }

  /**
   * Get registered EcoTrocks shop orders
   */
  getOrders(search: string, start: number, length: number, filter: string): Observable<EcotroksShopOrderTable> {
    return this.unavailableServiceService.handle(
      this.httpClient.get<EcotroksShopOrderTable>(`${this.msMetrics}/ecotroks/shopOperations?start=${start}&length=${length}${ search ? `&search=${search}` : '' }${ filter ? `&filter=${filter}` : '' }`),
      this.msEcotroksShop,
    );
  }

  markOrder(id: string, processed: boolean) {
    return this.unavailableServiceService.handle(
      this.httpClient.post<any>(`${this.msMetrics}/ecotroks/markShopOperations`, { id, processed }),
      this.msEcotroksShop,
    );
  }

  /**
   * Get one EcoTroks account based on the account _id
   *
   * @param accountID _id field of the ecotroks account
   */
  getAccount(accountID: string): Observable<EcotroksAccountJson> {
    return this.unavailableServiceService.handle(
      this.httpClient.get<EcotroksAccountJson>(`${this.msEcotroksAccounts}/${accountID}`),
      this.msEcotroksAccounts,
    );
  }

  /**
   * Get one EcoTroks account based on the account _id
   *
   * @param accountID _id field of the ecotroks account
   */
  getGainsTypes(): Observable<EcotroksGainTypes> {
    return this.unavailableServiceService.handle(
      this.httpClient.get<EcotroksGainTypes>(`${this.powervasCoreNode}/features/ecotroks_gains_types`),
      this.powervasCoreNode,
    );
  }

  /**
   * Get EcoTroks gains of one account based on the account _id
   *
   * @param accountID _id field of the ecotroks account
   */
  getAccountGains(accountID: string): Observable<EcotroksGainsJson> {
    return this.unavailableServiceService.handle(
      this.httpClient.get<EcotroksGainsJson>(`${this.msEcotroksAccounts}/${accountID}/gains`),
      this.msEcotroksAccounts,
    );
  }

  createArticle(article: EcotroksShopArticleJson) {
    return this.unavailableServiceService.handle(
      this.httpClient.post<any>(`${this.msEcotroksShop}/admin/articles`, article),
      this.msEcotroksShop,
    );
  }

  updateArticle(article: EcotroksShopArticleJson) {
    return this.unavailableServiceService.handle(
      this.httpClient.put<any>(`${this.msEcotroksShop}/admin/articles`, article),
      this.msEcotroksShop,
    );
  }

  ordersChart(from: Date, to: Date, articleLabelFilter: string, period: string) {
    let url = `${this.msMetrics}/ecotroks/orders-chart?from=${from.toISOString()}&to=${to.toISOString()}&period=${period}`;
    if (articleLabelFilter && articleLabelFilter.length) {
      url += `&articleFilter=${articleLabelFilter}`;
    }

    return this.unavailableServiceService.handle(
      this.httpClient.get<EcotroksOrdersChart>(url),
      this.msMetrics,
    );
  }

  transactionsForUser(userID: string): Observable<Array<EcotroksTransaction>> {
    const url = `${this.msMetrics}/ecotroks/transactions/${userID}`;

    return this.unavailableServiceService.handle(
      this.httpClient.get<any>(url),
      this.msMetrics,
    );
  }
}
