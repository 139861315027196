
<div class="total-container">
    <h3>Solde actuel : {{ currentBalance }} <img class="ecotroks-logo" src="assets/images/logoEcotroks.svg" alt="ecotroks"/></h3>
</div>

<nb-list>
    <nb-list-item class="transaction" *ngFor="let transaction of transactions">
        <span>
            <span class="value" [ngClass]="transaction.value > 0 ? 'credit' : 'debit'">
                {{ transaction.value > 0 ? '+' : '' }}{{ transaction.value }}
            </span>

            <span>{{ '' + transaction.name | translate }}</span>
        </span>


        <span>{{ formatDate(transaction.date) }}</span>
    </nb-list-item>
</nb-list>
