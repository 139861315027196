import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { SimulationModeService } from '../../services/simulation-mode.service';
import { UserJson } from '../../../@types/UserJson';
import { UsersJson } from '../../../@types/UserJson';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'gp-simulation-alert',
  templateUrl: './simulation-alert.component.html',
  styleUrls: ['./simulation-alert.component.scss']
})
export class SimulationAlertComponent implements OnInit {

  translations: any;
  user: UserJson;
  applicationLink = '/';

  constructor(
    private translateService: TranslateService,
    private backend: UserService,
    private simulationModeService: SimulationModeService) {}

  ngOnInit() {
    this.translateService.onLangChange.subscribe(() => this.onLanguageChange());
    this.onLanguageChange();

    if (!this.simulationModeService.isEnabled()) {
      return;
    }

    this.backend.getUser(this.simulationModeService.getSimulatedUserID())
        .subscribe((users: UsersJson) => {
          if (users.users && users.users.length) {
            [this.user] = users.users;
            this.applicationLink = this.simulationModeService.getLink();
          }
        });
  }

  onLanguageChange() {
    this.translateService.get('simulation').subscribe(data => {
        this.translations = data;
        this.translateService
          .get('common')
          .subscribe(common => this.translations.common = common);
      });
  }

  quitSimulation() {
    this.simulationModeService.disable();
  }
}
