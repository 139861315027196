import {Component, OnInit} from '@angular/core';
import * as moment from 'moment';
import {CookieService} from 'ngx-cookie-service';
import {TranslateService} from '@ngx-translate/core';
import { UserService } from '../../../services/user.service';


@Component({
    selector: 'gp-language-picker',
    templateUrl: './language-picker.component.html',
    styleUrls: ['./language-picker.component.scss']
})
export class LanguagePickerComponent implements OnInit {
    visible = false;
    languages = [{value: 'fr', label: 'Français'}];
    selectedLanguage;
    languageSelectorVisibility = false;

    constructor(
        private cookieService: CookieService,
        private translateService: TranslateService,
        private userService: UserService,
    ) {}

    ngOnInit(): void {
        this.selectedLanguage = this.translateService.currentLang;
        this.userService.getUserGroups('current').subscribe((groups) => {
            const group = groups.groups.find(e => e.admin);
            if (group.adminConfig && group.adminConfig.availableLang) {
                this.languages = group.adminConfig.availableLang.map(e => ({ value: e.code, label: e.label }));
            }
            this.visible = this.languages.length > 1;
        });
    }

    onLanguageChange() {
        this.cookieService.set('lang', this.selectedLanguage, moment().add(13, 'month').toDate(), '/');
        this.translateService.use(this.selectedLanguage);
        this.languageSelectorVisibility = false;
        window.location.reload();
    }

    toggleLanguageSelectorVisibility() {
        this.languageSelectorVisibility = !this.languageSelectorVisibility;
    }
}
