import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { NbMenuService, NbSidebarService } from '@nebular/theme';

import { UserService } from '../../../services/user.service';
import { AuthService } from '../../../services/auth.service';

import { GroupJson } from '../../../@types/GroupJson';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'gp-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {
  userAdminGroup: GroupJson;

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    @Inject(DOCUMENT) private document: Document
    ) {}

  ngOnInit() {
    this.userService.getUserGroups('current').subscribe((groups) => {
      this.userAdminGroup = groups.groups.find(e => e.admin);
    });
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  async logout() {
    await this.authService.signout().toPromise();
    this.document.location.href = '/';
  }
}
