import { Component, OnInit, Input, OnChanges, TemplateRef } from '@angular/core';
import { NbDialogRef, NbDialogService } from '@nebular/theme';

@Component({
  selector: 'gp-user-invitation-link-modal',
  templateUrl: './user-invitation-link-modal.component.html',
  styleUrls: ['./user-invitation-link-modal.component.scss'],
})

export class UserInvitationLinkModalComponent implements OnInit {
  @Input() link: string | null = null;

  constructor(
    public ref: NbDialogRef<UserInvitationLinkModalComponent>) {}

  ngOnInit() {}

  close() {
    this.ref.close();
  }

}
