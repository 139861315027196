import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'gp-analytic-card',
  templateUrl: './analytic-card.component.html',
  styleUrls: ['./analytic-card.component.scss']
})
export class AnalyticCardComponent implements OnInit {

  @Input() columns: Array<{ label: string; fieldName: string }> = [];
  @Input() data: Array<any> = [];
  @Input() height = 'auto';
  @Input() showIndex = true;
  @Input() nbSpinner = false;

  revealed = false;

  constructor() { }

  ngOnInit(): void {
  }

  toggleView() {
    this.revealed = !this.revealed;
  }
}
