<nb-list>
  <nb-list-item class="item header-item">
    <span class="head" *ngIf="showIndex">#</span>
    <span class="head" *ngFor="let column of columns">{{ column.label }}</span>
  </nb-list-item>
  <nb-list-item class="item" *ngFor="let item of data; index as i">
    <span class="head col" *ngIf="showIndex">{{ i }}</span>
    <span class="col" *ngFor="let column of columns">{{ item[column.field] ? item[column.field].toLocaleString() : '' }}</span>
  </nb-list-item>
</nb-list>
