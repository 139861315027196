import { Component, Input, OnInit } from '@angular/core';
import { UserJson } from '../../../../@types/UserJson';
import { EcotroksService } from '../../../../services/ecotroks.service';
import { EcotroksTransaction } from '../../../../@types/EcotroksTransaction';
import * as moment from 'moment';

@Component({
  selector: 'gp-user-details-ecotroks',
  templateUrl: './user-details-ecotroks.component.html',
  styleUrls: ['./user-details-ecotroks.component.scss']
})
export class UserDetailsEcotroksComponent implements OnInit {
  @Input() user: UserJson;

  currentBalance = 2500;
  transactions: Array<{ value: number; name: string; date: Date }> = [];

  constructor(private ecotroksService: EcotroksService) { }

  async ngOnInit() {
    const account = await this.ecotroksService.getAccount(this.user.aid).toPromise();
    const rawTransactions = await this.ecotroksService.transactionsForUser(this.user._id).toPromise();
    const gainsTypes = await this.ecotroksService.getGainsTypes().toPromise();

    this.currentBalance = account.balance;
    this.transactions = rawTransactions.map(e => ({
      name: gainsTypes.ecotroks_gains_types.find(ee => ee.keys.indexOf(e.reason.name) >= 0)?.title || '?',
      value: (e.dst ? 1 : -1) * e.value,
      date: new Date(e.isodate),
    })).sort((a, b) => b.date.getTime() - a.date.getTime());
  }

  formatDate(date) {
    return moment(date).format('DD MMMM YYYY');
  }
}
